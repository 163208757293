import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { UserContext } from "../UserContext";

EditUserModal.propTypes = {
  setShowEditUserModal: PropTypes.func.isRequired
};

export default function EditUserModal({ setShowEditUserModal }) {
  const { logout } = useContext(UserContext);

  //FIXME The modal closes when it is clicked on.
  
  return (
      // Modal Start
      <div className="fixed z-50 inset-0 overflow-y-auto" id="editUser-modal">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg px-9 py-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full sm:p-12"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <h3 className="mb-4 font-serif text-xl md:text-2xl leading-6 text-gray-900">Edit User</h3>
              <form className="space-y-8">
                <div className="max-h-72 sm:max-h-full overflow-auto sm:overflow-visible mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        defaultValue="Cody"
                        className="shadow-sm block w-full text-sm border px-3 py-2 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        defaultValue="Wilke"
                        className="shadow-sm block w-full text-sm border px-3 py-2 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        defaultValue="cody.wilke@fuel.dev"
                        className="shadow-sm block w-full text-sm border px-3 py-2 border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                      Role
                    </label>
                    <select
                      id="role"
                      name="role"
                      className="cursor-pointer mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option>Admin</option>
                      <option>New</option>
                      <option>Sponsor</option>
                      <option selected>User</option>
                    </select>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="reports"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Reports
                    </label>
                    <div className="mt-1 relative">
                      <div
                        id="reports"
                        className="bg-gray-50 min-h-12 shadow-sm block w-full text-sm border px-3 pt-3 pb-1 border-gray-300 rounded-md"
                      >
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          Ediary Time Comparison Report
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove Ediary Time Comparison Report</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          Collins Daily Log of Patient Check-Ins
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove Collins Daily Log of Patient Check-Ins</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          UOC Quarterly Results
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove UOC Quarterly Results</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          Mainstay Management Daily Update
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Mainstay Management Daily Update</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          Example A
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove Example A</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                      </div>
                      <div className="max-h-32 overflow-auto origin-top-left absolute left-0 mt-2 -mr-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabIndex="-1">
                        <div className="py-1" role="none">
                          {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
                          <a href="/admin-static" className="hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-0">
                            Ediary Compliance Report Site Level Houston
                          </a>
                          <a href="/admin-static" className="hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-1">
                            Ediary Time Comparison Report
                          </a>
                          <a href="/admin-static" className="hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-2">
                            HTP IP Usage Report Subject Level 2
                          </a>
                          <a href="/admin-static" className="hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-2">
                            Harrison Comparison Foundation Stats
                          </a>
                          <a href="/admin-static" className="hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-2">
                            Subject Mismatches Report
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="sponsors"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsors
                    </label>
                    <div className="mt-1">
                      <div
                        id="sponsors"
                        className="min-h-12 shadow-sm block w-full text-sm border px-3 pt-3 pb-1 border-gray-300 rounded-md"
                      >
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          Mainstay Medical
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove Mainstay Medical</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                        <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full border border-gray-200 text-xs bg-white text-gray-600 mb-2 mr-2">
                          UOC
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-100 hover:text-red-500 focus:outline-none focus:bg-indigo-500"
                          >
                            <span className="sr-only">Remove UOC</span>
                            <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="mt-5 flex flex-col-reverse sm:flex-row sm:justify-end">
                  <button
                      type="button"
                      className="mt-3 w-full sm:w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base sm:text-sm font-medium sm:font-normal text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1"
                      onClick={() => setShowEditUserModal(false)}
                    >
                      Cancel
                  </button>
                  <button
                    onClick={() => setShowEditUserModal(false)}
                    type="button"
                    className="w-full sm:w-auto sm:ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base sm:text-sm font-medium sm:font-normal text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2"
                  >
                    Create User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );

}

