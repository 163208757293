import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../UserContext';
import PropTypes from 'prop-types';

// Inspired by:
// https://codesource.io/authenticating-react-app-with-firebase/
// https://medium.com/@thanhbinh.tran93/private-route-public-route-and-restricted-route-with-react-router-d50b27c15f5e

// It receives 2 props: the component to render if the condition is true and we use the ES6 spread operator to get the remaining parameters passed from the router.
// It checks if authenticated is true and renders the component passed, else it redirects to/login.
export function PrivateRoute({ component: Component, ...rest }) {

  const {
    authenticated,
  } = useContext(UserContext);

  return (

    <Route 
      {...rest}
      render={props => {
        // {...props} are the props from the Route: match, location, history
        // {...rest} are the props that are passed in from the HOC PrivateRoute
        return authenticated ? <Component {...props} {...rest} /> : <Redirect to='/login' />;
      }}
    />
  );

}

export function PublicRoute({ component: Component, restricted, ...rest }) {

  const {
    authenticated,
  } = useContext(UserContext);

  return (

    // restricted = false: meaning public route
    // restricted = true: meaning restricted route (restricted routes cannot be accessed when logged in, ie. "/login")
    <Route 
      {...rest}
      render={props => {
        // {...props} are the props from the Route: match, location, history
        // {...rest} are the props that are passed in from the HOC PrivateRoute
        return (restricted && authenticated) ? <Redirect to="/" /> : <Component {...props} {...rest} />;
        
      }}
    />
  );
}

/**
 * HOC that checks if user is an admin (populated from custom claims)
 * If admin, user passes through to component
 * If not admin, user is redirected to home route
 * @param {object} props 
 * @returns React component
 */
export function AdminRoute({ component: Component, ...rest }) {
  const {
    authenticated,
    userData,
  } = useContext(UserContext);

  // Kicks back to /login if not authenticated
  if (!authenticated) {return <Redirect to='/login' />;}

  const { isAdmin } = userData;

  return (
    // restricted = false: meaning public route
    // restricted = true: meaning restricted route (restricted routes cannot be accessed when logged in, ie. "/login")
    <Route 
      {...rest}
      render={props => {
        // {...props} are the props from the Route: match, location, history
        // {...rest} are the props that are passed in from the HOC PrivateRoute
        return userData && isAdmin ? <Component {...props} {...rest} /> : <Redirect to="/" />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

PublicRoute.propTypes = {
  component: PropTypes.elementType,
  restricted: PropTypes.bool,
};

AdminRoute.propTypes = {
  component: PropTypes.elementType,
};