import React from "react";
import { 
  NavLink, 
} from "react-router-dom";


export default function Route404() {
  return (
    <>
      <div className="bg-white rounded-lg shadow row-span-1 col-span-3 mb-6 lg:mb-0 px-4 py-5 sm:px-6 h-screen text-center flex flex-col items-center justify-center relative">
        <div className="z-10">
          <h1 className="font-serif text-2xl md:text-3xl leading-6 text-gray-900">404: Page Not Found</h1>
          <p className="mx-auto mt-4 text-gray-500">Go back to the <NavLink to={`/dashboard`} className="fuel-text-teal-bright font-medium hover:text-gray-900">Dashboard</NavLink></p>
        </div>
        <svg width="175" height="288" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute"><path d="M57.428 202.341h45.21v-3.569c.271-29.102 9.639-42.417 31.769-56.145C159.387 127.527 175 106.936 175 75.5 175 29.102 139.294 0 87.432 0 39.915 0 1.358 26.631 0 78.795h48.332c1.222-25.807 19.686-38.162 38.829-38.162 20.772 0 37.471 14.002 37.471 35.554 0 19.218-12.627 32.396-29.054 42.829-24.03 15.1-37.878 30.338-38.15 79.756v3.569zM81.187 288c16.02 0 29.732-13.453 29.868-30.2-.136-16.473-13.848-29.926-29.868-29.926-16.563 0-30.004 13.453-29.868 29.926-.136 16.747 13.305 30.2 29.868 30.2z" fill="#0FB7AE" fillOpacity="0.05"/></svg>
      </div>
    </>
  );
}