import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { UserContext } from "../../UserContext";
import AdminSection from './AdminSection';
import RemoveUserFromSponsorButton from './RemoveUserFromSponsorButton';

SponsorAdmin.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  currentSponsorMetadata: PropTypes.object,
  addReportToSponsor: PropTypes.func,
  parseNewDataArrayString: PropTypes.func,
}

export default function SponsorAdmin({
  state, 
  dispatch, 
  currentSponsorMetadata,
  addReportToSponsor,
  parseNewDataArrayString,
}) {
  
  const { 
    userData,
  } = useContext(UserContext);

  return (
    <div>
      <h2 className="mb-4 font-serif text-xl md:text-3xl leading-6 text-gray-900">Sponsor Admin</h2>

      <AdminSection header="Users">
        <div>
          <u><strong><p>This sponsors users</p></strong></u>
          {
            state.allUsers.length === 0 ? 
            <p>There are no users listed in this sponsors user list</p> : 
            <ul>
              {currentSponsorMetadata && Object.entries(currentSponsorMetadata.userIndex).map(([key, value]) => {
                return(
                  <li key={key}>
                    <p><RemoveUserFromSponsorButton uid={key} sponsor={currentSponsorMetadata.sponsorID} currentUserUID={userData.uid} />{key}: {value} || {state.allUsers.find(user => user.uid === key).displayName} || {`${state.allUsers.find(user => user.uid === key).sponsorList.includes(currentSponsorMetadata.sponsorID)}`}</p>
                  </li>
                )
              })}
            </ul>
          }
          <sub>userIndex list from sponsors data (uid and role) || name found in all user list || if user has sponsor in their sponsorList</sub>
        </div>
      </AdminSection>

      <AdminSection header="Reports">
        <div>
          <u><strong><p>This sponsors reports</p></strong></u>
          <ul>
            {state.reports.map((report, i) => {
              return(
                <li key={i}>
                  <p>{report.reportID}</p>
                </li>
              )
            })}
          </ul>
          <br />
          <u><strong><p>Add new report:</p></strong></u>
          <div className="mt-2">
            <div><strong>report data: </strong></div>
            <div className="mt-1">
              <div className="inline" >Category: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-category", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="custom"
                value={state.newReportCategory}/>
            </div>
            <div className="mt-1">
              <div className="inline" >Report ID: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-reportID", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="enrollment-by-site"
                value={state.newReportID}/>
                <span><em>No spaces allowed</em></span>
            </div>
            <div className="mt-1">
              <div className="inline" >Report Title: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-report-title", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="Enrollment by Site"
                value={state.newReportTitle}/>
            </div>
            <div className="mt-1">
              <div className="inline" >Status: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-status", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="Live"
                value={state.newReportStatus}/>
            </div>
            <div className="mt-1">
              <div className="inline" >Type: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-type", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="external"
                value={state.newReportType}/>
            </div>
            <pre className="my-2" style={{color: "blue"}}>{parseNewDataArrayString(state.newReportMetadataString).map((arr, i) => {
              return <span key={i}>{arr[0]}-{arr[1]}<br /></span>
            })}</pre>
            
          </div>
          <div className="mt-3">
            <span><strong>report payload: </strong></span>

            <div className="mt-1">
              <div className="inline" >Embedded URL: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-embed-URL", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder=""
                value={state.embedURL}/>
            </div>
            <div className="mt-1">
              <div className="inline" >External Source: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-external-source", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="data-studio"
                value={state.externalSource}/>
            </div>
            <div className="mt-1">
              <div className="inline" >Link to Report: </div>
              <input 
                onChange={(e) => dispatch({type: "set-new-report-link-to-report", payload: e.target.value})}
                className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder=""
                value={state.linkToReport}/>
            </div>

            <pre className="my-2" style={{color: "blue"}}>{parseNewDataArrayString(state.newReportPayloadString).map((arr, i) => {
              return <span key={i}>{arr[0]}-{arr[1]}<br /></span>
            })}</pre>
          </div>
          <div className="mt-3">
            <span><strong>report users: </strong></span>
            <input 
                onChange={(e) => dispatch({type: "set-new-report-user-index-string", payload: e.target.value})}
                className="shadow-sm mr-3 w-full sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
                placeholder="userUID,userUID,userUID"/>
            <span><em>List user UIDs to add to the report. No spaces, comma delaminated</em></span><br />
            <pre style={{color: "blue"}}>{state.newReportUserIndexString.split(',').map((item, i) => {
              return <span key={i}>{item}<br /></span>
            })}</pre>
          </div>

          <div>
            <span>Add Report to Sponsor: </span>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={addReportToSponsor}>Process</button>
          </div>
        </div>
      </AdminSection>

    </div>
    
  )
}