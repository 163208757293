import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

AdminSection.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
}

// useEffect(() => {
//   console.log('children : ', children)

// }, [children])

export default function AdminSection(props) {
  return (
    <div className="my-4 bg-white rounded-lg shadow row-span-1 col-span-3 mb-6 lg:mb-0 px-4 py-5 sm:px-6">
      <h3 className="mb-4 font-serif text-xl md:text-3xl leading-6 text-gray-900">{props.header}</h3>
      {props.children}
    </div>
  )
}
