import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { UserContext } from "../UserContext";

LogoutModal.propTypes = {
  setShowSignOutModal: PropTypes.func.isRequired
};

export default function LogoutModal({ setShowSignOutModal }) {
  const { logout } = useContext(UserContext);

  //FIXME The modal closes when it is clicked on.
  
  return (
      // Modal Start
      <div className="fixed z-50 inset-0 overflow-y-auto" id="logout-modal">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg px-9 py-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-12"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="text-center">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Sign Out
              </h3>
              <div className="mt-2">
                <p className="text-gray-500">
                  Are you sure you want to sign out?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                onClick={() => logout()}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2"
              >
                Yes
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1"
                onClick={() => setShowSignOutModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
  );

}

