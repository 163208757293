import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { removeSponsorFromUser } from './AdminContainer';

RemoveUserFromSponsorButton.propTypes = {
  uid: PropTypes.string,
  sponsor: PropTypes.string,
  currentUserUID: PropTypes.string,
}

export default function RemoveUserFromSponsorButton({ uid, sponsor, currentUserUID }) {

  const [ isRemoved, setIsRemoved ] = useState(false);

  if (uid === currentUserUID) {
    return <></>
  } else if (isRemoved) {
    return <span style={{"color": "blue"}}>User Removed</span>
  } else {
    return (
      <button 
        onClick={() => {
          removeSponsorFromUser(uid, sponsor)
            .then(() => {
              setIsRemoved(true);
            });
        }}
        className="mr-1">
        <span style={{"color": "red"}}>X</span>
      </button>
    )
  }
}
