import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/database";
import "firebase/firestore";
import 'firebaseui/dist/firebaseui.css';


const firebaseConfig = {
  apiKey: "AIzaSyBtHkTvOua7LT1i9B6v5mb8nj4gbm9d7x0",
  authDomain: "derive-83816.firebaseapp.com",
  projectId: "derive-83816",
  storageBucket: "derive-83816.appspot.com",
  messagingSenderId: "406466659549",
  appId: "1:406466659549:web:93f891baf70894955b0734",
  measurementId: "G-VGS606FVPS"
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

// Authentication
export const auth = firebase.auth();

function createGoogleProviderObject() {
  // https://firebase.google.com/docs/auth/web/google-signin
  // https://firebase.google.com/docs/reference/js/firebase.auth.GoogleAuthProvider#static-google_sign_in_method

  // Create google authintication provider
  const provider = new firebase.auth.GoogleAuthProvider();

  // Provider scopes
  provider.addScope('profile');
  provider.addScope('email');
  // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  
  // possible options here: https://developers.google.com/identity/protocols/oauth2/openid-connect#authenticationuriparameters
  provider.setCustomParameters({
    
    // Forces account selection even when one account is available.
    prompt: 'select_account',
    
    // // Forces password re-entry.
    // auth_type: 'reauthenticate',
  });

  return provider;
}

export function googleSignIn() {

  const googleProvider = createGoogleProviderObject();

  auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    
    return auth.signInWithPopup(googleProvider);
  })
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    // let credential = result.credential;

    // TODO Send each login attempt to a log, especially those that fail or are not on our list. 

    // // This gives you a Google Access Token. You can use it to access the Google API.
    // let token = credential.accessToken;
    // // The signed-in user info.
    // let user = result.user;
    // // ...

    
  }).catch((error) => {
    console.error(error);
    // // Handle Errors here.
    // let errorCode = error.code;
    // let errorMessage = error.message;
    // // The email of the user's account used.
    // let email = error.email;
    // // The firebase.auth.AuthCredential type that was used.
    // let credential = error.credential;
    // // ...
  });
}


// Database
export const firestore = firebase.firestore();

// Emulators
function emulatorConnect() {
  // Point to the emulator running on localhost.
  auth.useEmulator("http://localhost:9099");
  firestore.useEmulator("localhost", 8080);
}

// Conditionally use emulator 
if (window.location.hostname === "localhost") {
  emulatorConnect();
} 

// Functions URL
export const functionsURL = window.location.hostname === "localhost" ? "http://localhost:5001/derive-83816/us-central1/app/" : "https://us-central1-derive-83816.cloudfunctions.net/app/";


// // // Initialize the FirebaseUI Widget using Firebase.
// // export const ui = new firebaseui.auth.AuthUI(firebaseApp.auth());

// // // FirebaseUI config.
// export const uiConfig = {
//   callbacks: {
//     // signInSuccessWithAuthResult: function(authResult, redirectUrl) {
//     //   var user = authResult.user;
//     //   var credential = authResult.credential;
//     //   var isNewUser = authResult.additionalUserInfo.isNewUser;
//     //   var providerId = authResult.additionalUserInfo.providerId;
//     //   var operationType = authResult.operationType;
//     //   // Do something with the returned AuthResult.
//     //   // Return type determines whether we continue the redirect
//     //   // automatically or whether we leave that to developer to handle.
//     //   return true;
//     // },
//     // signInFailure: function(error) {
//     //   // Some unrecoverable error occurred during sign-in.
//     //   // Return a promise when error handling is completed and FirebaseUI
//     //   // will reset, clearing any UI. This commonly occurs for error code
//     //   // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
//     //   // occurs. Check below for more details on this.
//     //   return handleUIError(error);
//     // },
//     uiShown: function() {
//       // The widget is rendered.
//       // Hide the loader.
//       document.getElementById('loader').style.display = 'none';
//     }
//   },
//   credentialHelper: firebaseui.auth.CredentialHelper.NONE,
//   // Query parameter name for mode.
//   // queryParameterForWidgetMode: 'mode',
//   // // Query parameter name for sign in success url.
//   // queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
//   // // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
//   signInFlow: 'popup',
//   signInSuccessUrl: '/',
//   signInOptions: [
//     {
//       provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       // scopes: [
//       //   'https://www.googleapis.com/auth/contacts.readonly'
//       // ],
//       customParameters: {
//         // Forces account selection even when one account
//         // is available.
//         prompt: 'select_account'
//         // // Forces password re-entry.
//         // auth_type: 'reauthenticate'
//       }
//     },
//   ],
//   // tosUrl and privacyPolicyUrl accept either url string or a callback
//   // function.
//   // Terms of service url/callback.
//   tosUrl: '<your-tos-url>',
//   // Privacy policy url.
//   privacyPolicyUrl: '<your-privacy-policy-url>'
// };