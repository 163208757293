import { useState, useEffect, useCallback } from 'react';
import { firestore } from '../firebase';

/**
 * Custom hook handling several sponsor related state and functions.
 * sponsorList 
 * @param {Object} userData User data from database
 * @returns Object holding state and functions for this hook.
 */
export default function useSponsor(userData) {

  // A named sponsor list state
  const [ sponsorList, setSponsorList ] = useState([]);
  // Current sponsor from the sponsorList state
  const [ currentSponsor, setCurrentSponsor ] = useState({sponsorID: "", sponsorName: ""});
  const [ loadingCurrentSponsor, setLoadingCurrentSponsor ] = useState(true);
  const [ currentSponsorMetadata, setCurrentSponsorMetadata ] = useState();
  const [ hasNoSponsors, setHasNoSponsors ] = useState(false);

  // Populate the current sponsor metadata
  useEffect(() => {
    if (currentSponsor.sponsorID !== "") {
      setLoadingCurrentSponsor(true);
      firestore.collection("sponsors").doc(currentSponsor.sponsorID).get()
        .then( doc => {
          const value = doc.data();
          setCurrentSponsorMetadata({...value, uid: currentSponsor.sponsorID});
        })
        .finally(() => setLoadingCurrentSponsor(false));
    } else if (hasNoSponsors) {
      setLoadingCurrentSponsor(false);
    }
  }, [ currentSponsor, hasNoSponsors ]);

  // Gets the current sponsor list from the userData
  useEffect(() => {
    if (userData) {
      
      const userSponsorIDList = userData.sponsorList;

      if (userSponsorIDList.length === 0) {
        setHasNoSponsors(true);
        console.log("There are no sponsors for this user.");
      }
      
      // Returns an array of promises for the named sponsor objects to resolve at the same time
      const promiseArr = userSponsorIDList.map(async sponsorID => {
    
        // Gets the ref for the current sponsor
        const sponsorRef = firestore.collection("sponsors").doc(sponsorID);
        
        // Gets the sponsors data
        const sponsorData = await sponsorRef.get()
          .then(doc => doc.data())
          .catch(error => console.error("There was a problem getting the sponsors doc: ", error));
    
        // Gets stored in new array of promises
        return {sponsorID: sponsorID, sponsorName: sponsorData.sponsorName};
      });

      // Resolves all promises, each being a names sponsor object
      Promise.all(promiseArr)
        .then(resultsArr => {
          setSponsorList(resultsArr);
        })
        .catch(error => {
          console.error({
            myMessage: "There was a problem with the sponsor named list while resolving or setting the new data.",
            error: error,
          });
        });
    }
  }, [ userData ]);

  // Sets the current sponsor from the current sponsor list state.
  useEffect(() => {
    if (userData) {
      const currentSponsorNamed = sponsorList.find(sponsor => sponsor.sponsorID === userData.currentSponsorID);
      // Only sets the current sponsor if a named sponsor was found in the sponsor list.
      currentSponsorNamed && setCurrentSponsor(currentSponsorNamed);
    }
  }, [ userData, sponsorList ]);
 
  /**
   * Used to change the sponsor at the database level.
   * @param {string} userUID UID of the user, used to set new current sponsor
   * @param {*} newSponsorID UID of the sponsor to open
   */
  const handleCurrentSponsorChange = useCallback(
    async (userUID, newSponsorID) => {
      const userRef = firestore.collection("users").doc(userUID);
      
      await userRef.update({
        currentSponsorID: newSponsorID
      })
      .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating currentSponsorID: ", error);
      });
    },
    [  ]
  );

  return {
    sponsorList,
    currentSponsor,
    currentSponsorMetadata, 
    handleCurrentSponsorChange,
    loadingCurrentSponsor,
  };
}