import React, { 
  useState, 
  useEffect, 
  useContext, 
  useCallback, 
} from "react";
import { firebase } from '@firebase/app';
import { firestore } from "../firebase";
import PropTypes from "prop-types";
import { 
  Switch, 
  Route, 
  useRouteMatch 
} from "react-router-dom";
import useReportList from '../hooks/useReportList';
import { UserContext } from '../UserContext';
import { ReportDetail } from './ReportDetail';
import { ReportListContainer } from './ReportListContainer';

export default function ReportsContainer() {
  // TODO: Need a UI to display when changes to the report list on the client have been saved on the server

  const { path } = useRouteMatch();
  
  const { userData } = useContext(UserContext);
  const { reportsMetadata } = useReportList(userData);

  const [fullReportList, setFullReportList] = useState([]);
  const [filterOn, setFilterOn] = useState("all");
  const [searchVal, setSearchVal] = useState("");

  // Sets the initial state of the fullReportList once the report list has loaded
  useEffect(() => {
    setFullReportList(reportsMetadata);
  }, [ reportsMetadata ]);

  function onSearchChange(e) {
    setSearchVal(e.target.value.toUpperCase());
  }

  /**
   * When a report is viewed, update the timestamp in the database for the current user
   */
  const updateReportViewTimestamp = useCallback((reportID) => {
    const reportRef = firestore.collection("users").doc(userData.uid).collection("user_reports").doc(reportID);
    reportRef.update({lastViewed: firebase.firestore.FieldValue.serverTimestamp()});
  }, [ userData.uid ]);

  function onStarChange(reportUID) {

    const arr = fullReportList.map(report => {
      if(report.reportUID === reportUID) {
        const newReport = {...report};
        newReport.isFavorite = !report.isFavorite;
        return newReport;
      }
      return report;
    });
    setFullReportList(arr);
  }

  return (
    <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">

      <Switch>

        <Route exact path={path}>
          <div className="flex flex-col md:flex-row justify-between align-center mb-4 md:mb-6 lg:mb-9">
            <h3 className="font-serif text-2xl md:text-3xl leading-6 text-gray-900 mb-4 md:mb-0">
              Reports
            </h3>
            <div id="report-page-filters" className="flex">
              <FavoriteToggle 
                setFilterOn={setFilterOn}
                filterOn={filterOn}
              />
              <SearchControl 
                onSearchChange={onSearchChange}
              />
            </div>
          </div>

          <ReportListContainer 
            filterOn={filterOn}
            fullReportList={fullReportList}
            onStarChange={onStarChange}
            searchVal={searchVal}
          />

        </Route>

        <Route path={`${path}/:reportID`}>
          <ReportDetail 
            fullReportList={fullReportList}
            onStarChange={onStarChange}
            updateReportViewTimestamp={updateReportViewTimestamp}
          />
        </Route>
        
      </Switch>
      
    </div>
  );
}

SearchControl.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

function SearchControl({ onSearchChange }) {
  return (
    <div className="relative rounded-md shadow-sm flex-grow md:flex-grow-0 lg:w-80">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none flex-grow">
        <svg
          className="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>
      <input
        type="text"
        name="search"
        id="search"
        className="pl-10 text-sm border px-3 py-2 border-gray-300 rounded-md w-full"
        placeholder="Search reports"
        onChange={(e) => onSearchChange(e)}
      />
    </div>
  );
}

FavoriteToggle.propTypes = {
  filterOn: PropTypes.string.isRequired,
  setFilterOn: PropTypes.func.isRequired,
};

function FavoriteToggle({ filterOn, setFilterOn }) {

  return (
      <span className="flex-grow-0 relative z-0 inline-flex shadow-sm rounded-md mr-5">
        <button
          onClick={() => {
            setFilterOn("all");
          }}
          type="button"
          className={
            `relative inline-flex items-center px-3 sm:px-4 py-1 sm:py-2 rounded-l-md border border-gray-300 text-sm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 ${filterOn === "all" ? "bg-gray-50 font-medium text-gray-900" : "bg-white -ml-px text-gray-500"}`
          }
        >
          All
        </button>
        <button
          onClick={() => {
            setFilterOn("fav");
          }}
          type="button"
          className={
            `relative inline-flex items-center px-3 sm:px-4 py-1 sm:py-2 rounded-r-md border border-gray-300 text-sm hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 ${filterOn === "fav" ? "bg-gray-50 font-medium text-gray-900" : "bg-white -ml-px text-gray-500"}`
          }
        >
          Favorites
        </button>
      </span>
  );
}