// component for dashboard
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { fetchJSON } from "../helpers/fetchHelpers";
import PropTypes from "prop-types";
import QuickLink from "./QuickLink";
import Modal from "./Modal";
import { RecentlyViewedReports } from "./RecentlyViewedReports";

function DashboardSection({ children }) {
  return children;
}

Dashboard.propTypes = {
  hasSponsors: PropTypes.bool,
  featuredReportEmbededURL: PropTypes.string
}

export default function Dashboard({ hasSponsors, featuredReportEmbededURL }) {
  const [showModal, setShowModal] = useState(false);

  // For modal example
  const [modalData, setModalData] = useState(null);

  // todo Move this to the database
  const linksMap = new Map([
    [
      "new-user-request",
      "https://forms.monday.com/forms/embed/4a91fc0ed78ae0b8ec30a5b93a09775b?r=use1",
    ],
    [
      "new-project-request",
      "https://forms.monday.com/forms/embed/3fccc5d7aaf546cbbd33f459ca71fb56?r=use1",
    ],
    [
      "new-item-request",
      "https://forms.monday.com/forms/embed/6fa12808fde9c70b92f445f2822866fd?r=use1",
    ],
    [
      "delete-form-request",
      "https://forms.monday.com/forms/embed/064354df8040ceab730ee30e336fe77d?r=use1",
    ],
    ["users-tracker", "/"],
    ["projects-tracker", "/"],
    ["items-tracker", "/"],
    ["form-history-tracker", "/"],
  ]);
  
  //No Sponsor
  if (!hasSponsors) {
    return (
    <DashboardSection>
      <div
        className="bg-white rounded-lg shadow row-span-1 col-span-3 mb-6 lg:mb-0 px-4 py-5 sm:px-6"
      >
        <div className="text-center py-36 px-8">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 67" style={{ width: "63" }} className="mx-auto mb-4"><path d="M60 27.6c-2.6 1.5-5.7 2.4-9 2.4-9.9 0-18-8.1-18-18 0-2.9.7-5.6 1.9-8H6c-3.3 0-6 2.7-6 6v51c0 3.3 2.7 6 6 6h48c3.3 0 6-2.7 6-6V27.6z" fillRule="evenodd" clipRule="evenodd" fill="#0fb7ae" fillOpacity=".125"/><path d="M60 12c0 5-4 9-9 9-1.9 0-3.7-.6-5.2-1.7L58.3 6.8C59.4 8.3 60 10.1 60 12zm-3.8-7.3L43.7 17.2c-1-1.5-1.7-3.3-1.7-5.2 0-5 4-9 9-9 1.9 0 3.7.6 5.2 1.7zM63 12c0 6.6-5.4 12-12 12s-12-5.4-12-12S44.4 0 51 0s12 5.4 12 12z" fillRule="evenodd" clipRule="evenodd" fill="#0f7075"/><path d="M9 31h6v24H9V31zm12-15h6v39h-6V16zm24 21h6v18h-6V37zm-12 6h6v12h-6V43z" fill="#15b7ae"/></svg>
          <h1 className="font-serif text-2xl md:text-3xl leading-6 text-gray-900">No sponsor data available</h1>
          <p className="mx-auto mt-4 text-gray-500"><NavLink to={`/contact`} className="fuel-text-teal-bright font-medium hover:text-gray-900">Contact support</NavLink></p>
        </div>
      </div>
    </DashboardSection>
    )
  }

  return (
    <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
      <div className="lg:grid grid-flow-row grid-rows-2-minmax-auto grid-cols-3 gap-9">
        {/* For modal example */}
        {showModal && (
          <Modal setShowModal={setShowModal} modalData={modalData}></Modal>
        )}
        {/* Latest Report */}
        <DashboardSection>
          <div
            id="dashboard-top-wide-window"
            className="bg-white rounded-lg shadow row-span-1 col-span-3 mb-6 lg:mb-0 px-4 py-5 sm:px-6"
            onClick={() => {
              // for testing
              fetchJSON("hello", { SPONSOR: "FUEL" })
                .then((m) => console.log(m))
                .catch((e) => console.log(e.message));
            }}
          >
            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
              <div style={{textAlign: 'center', flex: 1}}>
                <h3 className="font-serif text-xl md:text-2xl leading-6 text-gray-900" >
                  Welcome to Derive!
                </h3>
              </div>
              {/* <div className="flex-shrink-0">
                <NavLink
                  to="/reports"
                  type="button"
                  className="relative inline-flex items-center px-2.5 py-1.5 border border-transparent shadow-sm text-xs font-medium rounded-md text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  View Reports
                  <svg
                    className="ml-1 sm:ml-2 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </NavLink>
              </div> */}
            </div>
            {/* <img
              className="w-full mt-6"
              src="/images/chart-area.png"
              alt="chart example"
            /> */}
            {/* <div className="w-full h-auto mt-6">
              <iframe
                id="report-iframe"
                title="featured"
                className="w-full h-96 mt-6"
                src={featuredReportEmbededURL}
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
              />
            </div> */}
          </div>
        </DashboardSection>
        {/* Quick Links */}
        {/* <DashboardSection>
          <div className="bg-white rounded-lg shadow row-span-2 col-span-1 mb-6 lg:mb-0 px-4 py-5 sm:px-6">
            <h3 className="font-serif text-xl md:text-2xl leading-6 text-gray-900">
              Quick Links
            </h3>
            <div className="flex flex-wrap">
              <div className="space-y-3 mt-6 lg:mt-9 mr-12 lg:mr-9 2xl:mr-12">
                <h4 className="text-xs font-medium text-gray-500 opacity-80 uppercase tracking-wider">
                  Actions
                </h4>
                <nav className="space-y-3 text-sm 2xl:text-base text-gray-500 font-medium flex flex-col truncate">
                  <QuickLink 
                    variant="dashboard"
                    text="New User"
                    task="new-user-request"
                    drawPath="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                  <QuickLink 
                    variant="dashboard"
                    text="New Project"
                    task="new-project-request"
                    drawPath="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                  <QuickLink 
                    variant="dashboard"
                    text="New Item"
                    task="new-item-request"
                    drawPath="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                  <QuickLink 
                    variant="dashboard"
                    text="Delete Form"
                    task="delete-form-request"
                    drawPath="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  /> */}

                  {/* MODAL VERSION */}
                  {/* <button
                    className={
                      "group flex items-center w-auto hover:text-gray-900"
                    }
                    onClick={() => {
                      setModalData(linksMap.get("new-user-request"));
                      setShowModal(true);
                    }}
                  >
                    <svg
                      className="h-5 mr-2 fuel-text-teal-bright group-hover:text-teal-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                      />
                    </svg>
                    New User
                  </button>
                  <button
                    className={
                      "group flex items-center w-auto hover:text-gray-900"
                    }
                    onClick={() => {
                      setModalData(linksMap.get("new-project-request"));
                      setShowModal(true);
                    }}
                  >
                    <svg
                      className="h-5 mr-2 fuel-text-teal-bright group-hover:text-teal-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    New Project
                  </button>
                  <button
                    className={
                      "group flex items-center w-auto hover:text-gray-900"
                    }
                    onClick={() => {
                      setModalData(linksMap.get("new-item-request"));
                      setShowModal(true);
                    }}
                  >
                    <svg
                      className="h-5 mr-2 fuel-text-teal-bright group-hover:text-teal-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    New Item
                  </button>

                  <button
                    className={
                      "group flex items-center w-auto hover:text-gray-900"
                    }
                    onClick={() => {
                      setModalData(linksMap.get("delete-form-request"));
                      setShowModal(true);
                    }}
                  >
                    <svg
                      className="h-5 mr-2 fuel-text-teal-bright group-hover:text-teal-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                    Delete Form
                  </button> */}
                {/* </nav>
              </div>
              <div className="space-y-3 mt-6 lg:mt-9">
                <h4 className="text-xs font-medium text-gray-500 opacity-80 uppercase tracking-wider">
                  Trackers
                </h4>
                <nav className="space-y-3 text-sm 2xl:text-base text-gray-500 font-medium flex flex-col">
                  <QuickLink
                    variant="dashboard"
                    text="Users"
                    task="users-tracker"
                    drawPath="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                  <QuickLink
                    variant="dashboard"
                    text="Projects"
                    task="projects-tracker"
                    drawPath="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                  />
                  <QuickLink
                    variant="dashboard"
                    text="Items"
                    task="items-tracker"
                    drawPath="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                  <QuickLink
                    variant="dashboard"
                    text="Form History"
                    task="form-history-tracker"
                    drawPath="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </nav>
              </div>
            </div>
          </div>
        </DashboardSection> */}
        {/* Current Projects */}
        <DashboardSection>
          {/* <div className="bg-white rounded-lg shadow row-span-2 col-span-2 px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between flex-wrap sm:flex-nowrap mb-6">
              <h3 className="font-serif text-xl md:text-2xl leading-6 text-gray-900">
                Recently Viewed Reports
              </h3>
              <NavLink
                to={`/reports`}
                className="flex-shrink-0 group inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                View All
                <svg
                    className="ml-1 sm:ml-2 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </NavLink>
            </div>

            <div className="overflow-hidden border border-gray-200 rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 md:px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th scope="col" className="relative px-3 lg:px-6 py-3">
                      <span className="sr-only">Link</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                      <a href="#" className="hover:underline">Random Title</a>
                    </td>
                    <td className="hidden sm:table-cell tpx-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Live
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href="#"
                        className="group fuel-text-teal hover:text-gray-900"
                      >
                        View
                        <span className="ml-1 hidden sm:inline-block opacity-0 group-hover:opacity-100">
                          →
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                      <a href="#">Random Title</a>
                    </td>
                    <td className="hidden sm:table-cell tpx-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Live
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href="#"
                        className="group fuel-text-teal hover:text-gray-900"
                      >
                        View
                        <span className="ml-1 hidden sm:inline-block opacity-0 group-hover:opacity-100">
                          →
                        </span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                      <a href="#">Random Title</a>
                    </td>
                    <td className="hidden sm:table-cell tpx-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Live
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href="#"
                        className="group fuel-text-teal hover:text-gray-900"
                      >
                        View
                        <span className="ml-1 hidden sm:inline-block opacity-0 group-hover:opacity-100">
                          →
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          <RecentlyViewedReports />
        </DashboardSection>
      </div>
    </div>
  );
}
