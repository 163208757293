import React from "react";
import PropTypes from "prop-types";

export default function Login({ googleSignIn }) {  
  return (
    <div className="min-h-screen flex flex-col justify-center pb-12 sm:px-6 lg:px-8 relative">
      <div className="sm:mx-auto sm:w-full sm:max-w-md z-10">
        <svg
          className="mx-auto w-auto"
          width="48"
          height="48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.577 24A1.588 1.588 0 0112 22.423v-8.846c0-.862.715-1.577 1.577-1.577h7.269A3.144 3.144 0 0024 8.846V1.577C24 .715 24.715 0 25.577 0h20.846C47.285 0 48 .715 48 1.577v8.846c0 .862-.715 1.577-1.577 1.577H27.154A3.144 3.144 0 0024 15.154v5.692A3.144 3.144 0 0027.154 24h7.269c.862 0 1.577.715 1.577 1.577v8.846c0 .862-.715 1.577-1.577 1.577h-7.269A3.144 3.144 0 0024 39.154v7.269c0 .862-.715 1.577-1.577 1.577h-8.846A1.588 1.588 0 0112 46.423v-8.846c0-.862.715-1.577 1.577-1.577h4.41A6.005 6.005 0 0024 29.988a6.005 6.005 0 00-6.012-6.013h-4.411V24z"
            fill="#0C7075"
          />
          <path
            d="M6.012 24A6.005 6.005 0 000 30.012a6.005 6.005 0 006.012 6.013 6.005 6.005 0 006.013-6.013C12 26.686 9.315 24 6.012 24z"
            fill="#7DD630"
          />
        </svg>
        <h2 className="mt-6 mb-4 text-center text-4xl font-serif leading-6 text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md z-10">
        <div className="bg-white py-10 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col items-center">
            <button
              onClick={() => googleSignIn()}
              to="/dashboard"
              className="w-full inline-flex justify-center py-3 px-5 border border-gray-300 rounded-md shadow-sm bg-white font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
            >
              <svg
                className="w-5 h-5"
                width="21"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.1 10.227c0-.709-.064-1.39-.182-2.045H10.5v3.868h5.382a4.6 4.6 0 01-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35z"
                  fill="#4285F4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.5 20c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123H1.564v2.59A9.996 9.996 0 0010.5 20z"
                  fill="#34A853"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.905 11.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9V5.51H1.564A9.996 9.996 0 00.5 10c0 1.614.386 3.14 1.064 4.49l3.34-2.59z"
                  fill="#FBBC05"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.5 3.977c1.468 0 2.786.505 3.823 1.496l2.868-2.868C15.459.99 13.195 0 10.5 0a9.996 9.996 0 00-8.936 5.51l3.34 2.59c.787-2.364 2.991-4.123 5.596-4.123z"
                  fill="#EA4335"
                />
              </svg>
              <span className="ml-3">Login with Google</span>
          </button>

          <a href={`mailto:help@fuel.dev?subject=Request from Derive`}
              target='_blank'
              rel='noreferrer' className="mt-4 text-sm text-gray-500 hover:text-gray-900">
            Need help? Contact us
          </a>
        </div>
      </div>
      <svg
        className="absolute z-0 hidden sm:block mt-28"
        style={{ left: "-466" }}
        width="931"
        height="540"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1h509.464C589.633 1 654.59 66.025 654.59 146.279c0 56.193 45.485 101.726 101.619 101.726H931"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 296.584H756.209c-56.134 0-101.619-45.533-101.619-101.726 0-80.254-64.957-144.061-145.126-144.061H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 345.162H756.209c-56.134 0-101.619-45.533-101.619-101.725 0-80.254-64.957-142.995-145.278-142.995H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 393.741H756.209c-56.134 0-101.619-45.533-101.619-101.726 0-80.406-65.109-141.776-145.278-141.776H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 442.32H756.209c-56.134 0-101.619-45.533-101.619-101.726 0-80.406-65.109-140.711-145.43-140.711H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 490.898H756.209c-56.134 0-101.619-45.533-101.619-101.725 0-80.407-65.109-139.493-145.43-139.493H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
        <path
          d="M931 539.477H756.209c-56.134 0-101.619-45.533-101.619-101.726 0-80.406-65.109-138.426-145.582-138.426H0"
          stroke="#7DD630"
          strokeOpacity=".65"
          strokeMiterlimit="10"
        />
      </svg>
      <img
        className="absolute z-0 hidden sm:block right-0 bottom-24 -right-36"
        src="/images/halftone-circle.png"
        width="250"
        height="250"
        alt="halftone"
      />
    </div>
  );
}

Login.propTypes = {
  googleSignIn: PropTypes.func
};