import React, { useCallback, useContext, } from "react";
import PropTypes from "prop-types";
import { firestore } from '../firebase';
import { UserContext } from "../UserContext";

ReportFavoriteStar.propTypes = {
  reportUID: PropTypes.string,
  isFavorite: PropTypes.bool,
  onStarChange: PropTypes.func.isRequired,
};

export function ReportFavoriteStar({ reportUID, isFavorite, onStarChange }) {
  
  const { userData } = useContext(UserContext);

  /**
   * Updates the value of the favorite status within the users report collection data
   * @param {String} reportID Report Document ID to update in the users reports
   * @param {boolean} setTo New favorite value
   */
  const updateFavorite = useCallback((userUID, reportUID, setTo) => {
    const userReportRef = firestore.collection("users").doc(userUID).collection("user_reports").doc(reportUID);
  
    // Updates user report data and sets savingData state to true while the request is pending
    return userReportRef.update({isFavorite: setTo})
      .catch(error => {
        console.error("There was an error saving the user data. ", error);
      });
  }, []);


  return (
    <>
        <svg
          id="favorite-star"
          className={`cursor-pointer ${isFavorite ? "h-5 fuel-text-teal-bright" : "h-5 text-gray-400"} `}
          xmlns="http://www.w3.org/2000/svg"
          fill={ isFavorite ? "currentColor" : "none" }
          viewBox="0 0 24 24"
          stroke="currentColor"
          onClick={() => {
            onStarChange(reportUID);
            updateFavorite(userData.uid, reportUID, !isFavorite);
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
          />
        </svg>
    </>
  );
}