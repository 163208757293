import React, { 
  useContext, 
  useState, 
  useEffect, 
} from "react";
import { UserContext,} from '../UserContext';
import useSponsor from '../hooks/useSponsor';
import PropTypes from "prop-types";
import { NavLink, useRouteMatch } from "react-router-dom";

NavbarSponsorSelector.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  sponsorSelectorIsOpen: PropTypes.bool.isRequired,
  setSponsorSelectorIsOpen: PropTypes.func.isRequired,
  handleCurrentSponsorChange: PropTypes.func.isRequired,
};

export default function NavbarSponsorSelector({ 
  isCollapsed, 
  handleCurrentSponsorChange, 
  setSponsorSelectorIsOpen, 
  sponsorSelectorIsOpen, 
}) {

  const { userData }  = useContext(UserContext);

  const {
    currentSponsor,
    sponsorList,
  } = useSponsor(userData);
  
  return (
    <div id="sponsor-selector" className={`p-4 relative w-full  ${isCollapsed ? "visible" : "sm:invisible"}`}>
      
      {
        sponsorSelectorIsOpen && 
        <div className="absolute left-4 bg-white mb-2 border border-gray-300 rounded-md shadow-sm py-2 cursor-default focus:outline-none focus:ring-1 text-sm text-gray-500" style={{ width: "calc(100% - 32px)", bottom: 60 }}>
          {sponsorList.map(({sponsorID, sponsorName}, i) => (
            <SponsorOption 
              key={i}
              sponsorID={sponsorID}
              sponsorName={sponsorName}
              setSponsorSelectorIsOpen={setSponsorSelectorIsOpen}
              handleCurrentSponsorChange={handleCurrentSponsorChange}
              sponsorSelectorIsOpen={sponsorSelectorIsOpen}
              userData={userData}
            />
          ))}
        </div>
      }

      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        className="group relative w-full cursor-pointer border border-gray-500 rounded-md shadow-sm pl-3 pr-10 py-2 text-left text-sm text-gray-400 hover:text-gray-200 focus:outline-none focus:ring-1"
        onClick={() => setSponsorSelectorIsOpen(!sponsorSelectorIsOpen)}
      >
        <span className="block truncate">{currentSponsor.sponsorName}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-500 group-hover:text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
    </div>
  );
}

SponsorOption.propTypes = {
  sponsorSelectorIsOpen: PropTypes.bool.isRequired,
  setSponsorSelectorIsOpen: PropTypes.func.isRequired,
  handleCurrentSponsorChange: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  sponsorID: PropTypes.string.isRequired,
  sponsorName: PropTypes.string.isRequired,
};

function SponsorOption({
  sponsorID, 
  setSponsorSelectorIsOpen, 
  handleCurrentSponsorChange, 
  sponsorSelectorIsOpen, 
  userData, 
  sponsorName, 
}) {

  const match = useRouteMatch();

  // To identify which sponsor option is currently active
  const [ isActive, setIsActive ] = useState();
  useEffect(() => {setIsActive(userData.currentSponsorID === sponsorID);}, [sponsorID, userData.currentSponsorID]);

  return (
      <NavLink 
        className={`block py-1 px-3 text-sm relative flex-nowrap ${isActive ? "hover:bg-transparent cursor-auto text-gray-900 font-semibold flex justify-between" : "hover:bg-gray-200"}`}
        to={`/dashboard`} 
        onClick={() => {
          handleCurrentSponsorChange(userData.uid, sponsorID);
          setSponsorSelectorIsOpen(!sponsorSelectorIsOpen);
        }}>
          {sponsorName}
          {isActive &&
          <svg className="h-5 w-5" xxmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#0fb7ae" aria-hidden="true">
            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
          </svg>
          }
      </NavLink>
  );
  
}
