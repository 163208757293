import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { firestore } from '../firebase';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../UserContext'
import { fetchJSON } from "../helpers/fetchHelpers";

export default function NewUserSetup() {

  const { userLoginData } = useContext(UserContext);
  const [newUserConfig, setNewUserConfig] = useState(null);
  const [message, setMessage] = useState("Loading...");

  const history = useHistory()

  // Fetches new user data
  useEffect(() => {
    // This location is guaranteed to exist because triggering this component can only happen if the user has newUser data
    firestore.collection("newUsers").doc(userLoginData.email).get().then(docSnapshot => {
      const newUserData = docSnapshot.data();
      
      // Sets user account with blank sponsor/report data, those are handled in a separate request.
      setNewUserConfig({
        newUserConfig: {
          uid: userLoginData.uid,
          createdOn: new Date(),
          displayName: userLoginData.displayName,
          email: userLoginData.email,
          role: "user",
          sponsorList: [],
          currentSponsorID: "",
          reportList: [],
          userPhoto: userLoginData.photoURL,
        }, 
        newUserData: newUserData,
      })
    });

  }, [userLoginData]);

  useEffect(() => {
    
    if (newUserConfig !== null) {
      createUser(newUserConfig)

    }
  }, [history, newUserConfig, userLoginData.uid])
  

  return (
    <div className="h-screen flex flex-column items-center">
      <div className="flex flex-grow"></div>
      <div className="flex flex-col items-center flex-grow-0">
        <h1>Welcome to Derive!</h1>
        <p>Setting up new account</p>
        <p>{message}</p>
        <CircularProgress />
      </div>
      <div className="flex flex-grow"></div>
    </div>
  )

  function createUser(newUserConfig){
    // Create the user
    fetchJSON("create-new-user", newUserConfig.newUserConfig)
    .then(message => {
      setMessage(message);
      fetchJSON("set-user-admin", { userUID: userLoginData.uid, setAs: false })
      .then(message => {
        setMessage(message)
        const sponsorConfigs = createSponsorConfigs(newUserConfig.newUserData, userLoginData.uid);
        // console.log('Sponsor Congifs : ', sponsorConfigs)
        const sponsorFetchPromises = sponsorConfigs.map(config => fetchJSON("add-user-to-sponsor", config))
        Promise.all(sponsorFetchPromises)
        .then(message => {
          setMessage(message)
          const reportConfigs = createReportConfigs(newUserConfig.newUserData, userLoginData.uid);
          console.log('Report Configs : ', reportConfigs)
          const reportFetchPromises = reportConfigs.map(config => fetchJSON('add-user-to-report', config))
          Promise.all(reportFetchPromises)
          .then(message => {
            setMessage(message)
            history.go(0)
          })
          .catch(e => console.error(e.message)) 
        })
        .catch(e => console.error(e.message))
      })
      .catch(e => console.error(e.message))
    })
    .catch(e => console.error(e.message))
  }

  // Unused
  // async function addSponsors(){
  //   console.log('entered addSponsors')
  //     const sponsorConfigs = createSponsorConfigs(newUserConfig.newUserData, userLoginData.uid);
  //     const sponsorFetchPromises = await sponsorConfigs.map(config => fetchJSON("add-user-to-sponsor", config));

  //     await Promise.all(sponsorFetchPromises)
  //     .then(() => setMessage("Sponsors updated"))
  //     // .then(() => history.go(0))
  //     .catch(e => console.error(e.message));
  // }

  // async function addReports(){
  //   console.log('entered addReports')
  //   const reportConfigs = createReportConfigs(newUserConfig.newUserData, userLoginData.uid);
  //   const reportFetchPromises = await reportConfigs.map(config => fetchJSON('add-user-to-report', config));

  //   await Promise.all(reportFetchPromises)
  //     .then(() => setMessage("Reports updated"))
  //     // .then(() => history.go(0))
  //     .catch(e => console.error(e.message));
  // }

  // async function setAdminUser() {
  //   console.log('entered setAdmin')
  //   const adminFetchPromise = await fetchJSON("set-user-admin", { userUID: userLoginData.uid, setAs: false })
  //     .then((m) => {setMessage("Admin set false")
  //                   console.log('this is m : ', m)})
  //     .catch(e => console.error(e.message));

  //     const sponsorConfigs = createSponsorConfigs(newUserConfig.newUserData, userLoginData.uid);
  //     const sponsorFetchPromises = await sponsorConfigs.map(config => fetchJSON("add-user-to-sponsor", config));

  //     const reportConfigs = createReportConfigs(newUserConfig.newUserData, userLoginData.uid);
  //     const reportFetchPromises = await reportConfigs.map(config => fetchJSON('add-user-to-report', config));

  //     await Promise.all([adminFetchPromise, sponsorFetchPromises, reportFetchPromises])
  // }
}


function createSponsorConfigs(newUserData, userUID) {
  
  // Desired config shape:
  // { sponsorID: string, userUID: string, roleForSponsor: string }
  
  return newUserData.sponsorList.map(data => {
    return { sponsorID: data.sponsorID, userUID: userUID, roleForSponsor: data.roleForSponsor }
  });
}

function createReportConfigs(newUserData, userUID) {
  
  // Desired config shape:
  // { sponsorID: string, userUID: string, reportUID: string }

  return newUserData.reportList.map(data => {
    return { sponsorID: data.sponsorID, userUID: userUID, reportUID: data.reportUID }
  })
}

