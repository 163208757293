import React, { 
  useRef, 
  useContext, 
  useState,
  useEffect,
} from "react";
import { 
  Redirect, 
  Switch, 
  Route, 
} from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "../UserContext";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import ReportsContainer from "./ReportsContainer";
import ContactContainer from "./contact/ContactContainer";
import { AdminRoute } from "./CustomRoutes";
import AdminContainer from "./admin/AdminContainer";
import AdminStaticContainer from "./AdminStatic";
import LinearProgress from '@material-ui/core/LinearProgress';
import useSponsor from "../hooks/useSponsor";
import Route404 from './Route404';import useReportList from '../hooks/useReportList';


DefaultContainer.propTypes = {
  currentSponsorIDRef: PropTypes.object
};

/**
 * Users can only arrive here if they are authenticated and have a user account set up.
 */
export default function DefaultContainer() {

  const defaultRef = useRef();
  const [ loading, setLoading ] = useState(true);
  const [ isMobileOpen, setIsMobileOpen ] = useState(false);
  
  const { 
    userData, 
    userLoading, 
    userDataLoading, 
  } = useContext(UserContext);

  const { 
    handleCurrentSponsorChange, 
    loadingCurrentSponsor, 
  } = useSponsor(userData);

  // currentSponsorID is set to null until userData can set it
  const currentSponsorIDRef = useRef(null);

  const { 
    currentSponsorMetadata
  } = useSponsor(userData);


  const { reportsMetadata } = useReportList(userData);
  
  const [ featuredReportEmbededURL, setFeaturedReportEmbededURL ] = useState('')
  
  useEffect(() => {
    if (currentSponsorMetadata && currentSponsorMetadata.featuredReport){
      setFeaturedReportEmbededURL('')
      reportsMetadata.forEach(r => {
        console.log('Value of r : ', r)
        if(currentSponsorMetadata.featuredReport === r.reportUID){
          setFeaturedReportEmbededURL(r.payload.embedURL);
        }
      });
    }
  }, [userData, currentSponsorMetadata, reportsMetadata]);
  

  
  console.log('User Data : ', userData)
  console.log('Sponsor Metadata : ', currentSponsorMetadata)
  console.log('Report Metadata : ', reportsMetadata)

  // Responsible for setting currentSponsorID accordingly
  useEffect(() => {
    // Handles when currentSponsorID is blank, tries to add first sponsor as current sponsor

    if (userData !== null) {
      
      // if no current sponsor and a sponsor list exists
      if (userData.currentSponsorID === "" && userData.sponsorList.length > 0) {
        handleCurrentSponsorChange(userData.uid, userData.sponsorList[0]);
        currentSponsorIDRef.current = userData.sponsorList[0];
      
      // if no current sponsor and a sponsor list DOES NOT exists
      } else if (userData.currentSponsorID === "") {
        currentSponsorIDRef.current = "none";
      
      // current sponsor present
      } else {
        currentSponsorIDRef.current = userData.currentSponsorID;
      }
    }
  }, [ userData, handleCurrentSponsorChange ]);

  // For any loading state, set default loading state
  useEffect(() => {
    setLoading(true);
    !userLoading && !userDataLoading && !loadingCurrentSponsor && currentSponsorIDRef.current !== null && setLoading(false);
  }, [currentSponsorIDRef, loadingCurrentSponsor, userDataLoading, userLoading]);

  if (currentSponsorIDRef.current === null) {
    return <></>;
  } else {

    return (
      <div className={`App h-screen sm:flex fuel-bg-teal-lightest ${isMobileOpen ? "overflow-hidden" : ""}`} ref={defaultRef}>
        
        <div
          className="absolute inline-block transform text-lg uppercase text-gray-400 z-10 pointer-events-none"
          style={{ bottom: 12, right: 12 }}
          > Confidential
        </div>
        
        <Navbar 
          defaultRef={defaultRef} 
          hasSponsors={currentSponsorIDRef.current !== "none"} 
          isMobileOpen={isMobileOpen} 
          setIsMobileOpen={setIsMobileOpen} />
        
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex="0"
        >

          {/* Loading bar */}
          <div id="loading-bar-container" className="w-full absolute">
            {loading && <LinearProgress className="w-full absolute" />}
          </div>
          {
            <Switch>
              <Route exact from={"/"}>
                <Redirect to={"/dashboard"} />
              </Route>
              <AdminRoute path={"/admin"} component={AdminContainer} />
              <AdminRoute path={"/admin-static"} component={AdminStaticContainer} />
              <Route path="/dashboard">
                <Dashboard hasSponsors={currentSponsorIDRef.current !== "none"} featuredReportEmbededURL={featuredReportEmbededURL}/>
              </Route>
              <Route path="/reports" component={ReportsContainer} />
              <Route path="/contact">
                <ContactContainer hasSponsors={currentSponsorIDRef.current !== "none"} />
              </Route>
              <Route path="*" component={Route404} />
            </Switch>
          }
        </main>
      </div>
    );
  }
}