import React, { useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import PropTypes from "prop-types";
import { fetchJSON } from "./helpers/fetchHelpers";

export const UserContext = React.createContext();



async function checkAdminUser(uid) {
  console.log('In checkAdminUser')
  return await fetchJSON("check-user-admin", { userUID: uid })
    .then(res => JSON.parse(res))
    .catch(e => console.log(e.message));
}

export const UserProvider = ({ children }) => {

  const [ userLoginData, setUserLoginData ] = useState(null);
  const [ authenticated, setAuthenticated ] = useState(null);
  const [ userLoading, setUserLoading ] = useState(true);
  const [ userData, setUserData ] = useState(null);
  const [ userDataLoading, setUserDataLoading ] = useState(true);
  const [ newUserSetup, setNewUserSetup ] = useState(false);

  useEffect(() => {

    // onAuthStateChange is a listener that provides the current user.
    // This is Googles recommended method to get the current user
    const removeListener = auth.onAuthStateChanged(async (user) => {
      
      if (user) {
        // if the user is signed in

        const checkAdminUserResponse = await checkAdminUser(user.uid);

        const { 
          email, 
          displayName, 
          uid, 
          photoURL, 
        } = user;
        setAuthenticated(true);
        setUserLoginData({ 
          email, 
          displayName, 
          uid, 
          photoURL, 
          userIsAdmin: checkAdminUserResponse.isAdmin, 
        });
        setUserLoading(false);
      } else {
        // if the user is NOT signed in
        setAuthenticated(false);
        setUserLoginData(null);
        setUserLoading(false);
        setUserData(null);
        setUserDataLoading(false);
      }
  
    });
  
    return removeListener;
  }, []);

  // After the login data is fetched, retrieve the user data and store in state.
  useEffect(() => {
    
    // This sets up a mutable object to hold the unsubscribe function which is needed since this function establishes a listener
    const subscription = {unsubscribe: () => undefined}
    
    async function fetchUserData() {
  
      const uid = userLoginData.uid;
      const email = userLoginData.email;
      const isAdmin = userLoginData.userIsAdmin;
      const userRef = firestore.collection("users").doc(uid);

      const isOnNewUserList = await hasNewUserDocument(email);

      subscription.unsubscribe = userRef.onSnapshot((doc) => {

        if (doc.exists) {
          setUpListener(doc, isAdmin)
        } else if (!doc.exists && isOnNewUserList) {
          setNewUserSetup(true)
        } else {
          // doc.data() will be undefined in this case
          console.error("No user data!");
          setUserData(false);
          setUserDataLoading(false);
        }
      }, (error) => console.error("Error getting the user data:", error));

      // Uses the doc to set up a listener
      function setUpListener(doc, isAdmin) {
        const userData = doc.data();
        var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source, " data: ", userData);
        setUserData({...userData, isAdmin: isAdmin});
        setUserDataLoading(false);
      }

      async function hasNewUserDocument(email) {
        const docSnapshot = await firestore.collection("newUsers").doc(email).get();
        return docSnapshot.exists;
      }

    }
    
    // 
    if ( userLoginData !== null) {
      fetchUserData();
    }

    // Clean up listener
    return () => subscription.unsubscribe();

  }, [ userLoginData ]);

  function logout() {
    auth.signOut().then(() => {
      setAuthenticated(false);
      setUserDataLoading(false);
      setUserData(null);
    });
  }

  const value = {
    userData, // The data from the database
    userLoginData,
    authenticated,
    userLoading,
    logout,
    userDataLoading,
    newUserSetup,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element,
};
