import React, { 
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  firestore,
} from "../../firebase";
import { 
  Switch, 
  NavLink, 
  Route,
} from 'react-router-dom';
import { firebase } from '@firebase/app';
import { UserContext } from "../../UserContext"; 
import useSponsor from "../../hooks/useSponsor";
import SponsorAdmin from "./SponsorAdmin";
import GlobalAdmin from "./GlobalAdmin";
import { fetchJSON } from '../../helpers/fetchHelpers';

export default function AdminContainer() {

  const { userData } = useContext(UserContext);

  const initialState = {
    message: null,
    networkRequestLoading: false,
    allUsers: [],
    reports: [],
    newSponsorID: "",
    newSponsorName: "",
    fuelStaffContact: "",
    newSponsorUserIndex: "",
    newReportMetadataString: "",
    newReportCategory: "custom",
    newReportID: "",
    newReportTitle: "",
    newReportStatus: "",
    newReportType: "external",
    embedURL: "",
    externalSource: "data-studio",
    linkToReport: "",
    newReportPayloadString: "",
    newReportUserIndexString: "",
    fuelStaffList: [],
    newUserEmail: '',
    newUserSponsorList: '',
    newUserReportList: '',
    allReports: [],
    allSponsors: [],
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {

    switch (action.type) {
      case "set-message": return {...state, message: action.payload,}
      case "set-network-request-loading": return {...state, networkRequestLoading: action.payload,}
      case "set-all-users": return {...state, allUsers: action.payload,}
      case "set-reports": return {...state, reports: action.payload,}
      case "set-new-sponsor-id": return {...state, newSponsorID: action.payload,}
      case "set-new-sponsor-name": return {...state, newSponsorName: action.payload,}
      case "set-fuel-staff-contact": return {...state, fuelStaffContact: action.payload,}
      case "set-new-sponsor-user-index": return {...state, newSponsorUserIndex: action.payload,}
      case "set-new-report-metadata-string": return {...state, newReportMetadataString: action.payload,}
      case "set-new-report-payload-string": return {...state, newReportPayloadString: action.payload,}
      case "set-new-report-category": return {...state, newReportCategory: action.payload,}
      case "set-new-report-reportID": return {...state, newReportID: action.payload,}
      case "set-new-report-report-title": return {...state, newReportTitle: action.payload,}
      case "set-new-report-status": return {...state, newReportStatus: action.payload,}
      case "set-new-report-type": return {...state, newReportType: action.payload,}
      case "set-new-report-embed-URL": return {...state, embedURL: action.payload,}
      case "set-new-report-external-source": return {...state, externalSource: action.payload,}
      case "set-new-report-link-to-report": return {...state, linkToReport: action.payload,}
      case "set-new-report-user-index-string": return {...state, newReportUserIndexString: action.payload,}
      case "push-to-fuel-staff": return {...state, fuelStaffList: [...action.payload],}
      case 'set-new-user-email': return {...state, newUserEmail: action.payload,}
      case 'set-new-user-sponsor-list': return {...state, newUserSponsorList: action.payload,}
      case 'set-new-user-report-list': return {...state, newUserReportList: action.payload,}
      case 'set-all-sponsors': return {...state, allSponsors: action.payload,}
      default: throw new Error("Problem with the reducer");
    }
  }

  const {
    currentSponsor,
    currentSponsorMetadata, 
  } = useSponsor(userData);

  // Updates the state.newReportMetadataString value
  useEffect(() => {
    dispatch({type: "set-new-report-metadata-string", payload: `category~${state.newReportCategory};reportID~${state.newReportID};reportTitle~${state.newReportTitle};status~${state.newReportStatus};type~${state.newReportType}`})
  }, [state.newReportCategory, state.newReportID, state.newReportStatus, state.newReportTitle, state.newReportType])

  // Updates the state.newReportPayloadString value
  useEffect(() => {
    dispatch({type: "set-new-report-payload-string", payload: `embedURL~${state.embedURL};externalSource~${state.externalSource};linkToReport~${state.linkToReport}`})
  }, [state.embedURL, state.externalSource, state.linkToReport])

  useEffect(() => {
    state.message && console.log(state.message);
    // setTimeout(function() { dispatch({type: "set-message", payload: null}); }, 5000);
  }, [state.message]);

  // Get fuel staff list
  useEffect(() => {
    const fuelStaffRef = firestore.collection("admin").doc("metadata").collection("fuelStaff").get();
    fuelStaffRef.then((querySnapshot) => {
      const arr = [];
      querySnapshot.forEach(staff => {
        arr.push(staff.id)
      });
      dispatch({type: "push-to-fuel-staff", payload: arr})
    })
  }, []);

  /**
   * 
   * @param {object} sponsorConfig 
   */
  function addSponsorToDatabase() {
    const userIndex = new Map(parseNewDataArrayString(state.newSponsorUserIndex))

    const preloadedSponsor = {
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      sponsorID: state.newSponsorID,
      sponsorName: state.newSponsorName,
      fuelStaffContact: state.fuelStaffContact,
      userIndex: Object.fromEntries(userIndex)
    };

    try {
      const sponsorsRef = firestore.collection("sponsors").doc(state.newSponsorID);

      dispatch({type: "set-network-request-loading", payload: true});

      sponsorsRef.set(preloadedSponsor)
        .then(() => {
          dispatch({type: "set-network-request-loading", payload: false});
          dispatch({type: "set-message", payload: "Success"});
        })
        .catch(error => dispatch({type: "set-message", payload: error.message}));


      // Add to user reportList
      userIndex.forEach((val, key) => {
        console.log(key)
        const userRef = firestore.collection("users").doc(key);
  
        userRef.update({
          sponsorList: firebase.firestore.FieldValue.arrayUnion(state.newSponsorID)
        }).catch(e => {
          dispatch({type: "set-message", payload: e.message})
        })
      })
    } catch (e) {dispatch({type: "set-message", payload: e.message});}
  }

  function getAllUsers() {
    const usersRef = firestore.collection("users");

    const newState = []
    usersRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        newState.push(doc.data())
      });
    }).then(() => {
      dispatch({type: "set-all-users", payload: newState})
    });
  }
  useEffect(getAllUsers, []);


  function getSponsorsReports(sponsorID) {
    if (sponsorID) {
      try {
        const sponsorsReportsRef = firestore.collection("sponsors").doc(sponsorID).collection("reports");
  
        const newState =[];
        sponsorsReportsRef.get().then(snapshot => {
          snapshot.forEach(report => newState.push(report.data()))
        }).then(() => dispatch({type: "set-reports", payload: newState}))
  
      } catch (error) {
        console.log(error.message)
      }
    }
  }
  useEffect(() => getSponsorsReports(currentSponsor.sponsorID), [currentSponsor])


  function parseNewDataArrayString(string) {
    if (string !== undefined || string !== null ) {
      // uid-role;uid-role
      let arr = string.split(';')
      let newArr = arr.map(innerArr => innerArr.split("~"))
      return newArr
    }
  }

  function addReportToSponsor() {
    const payload = new Map(parseNewDataArrayString(state.newReportPayloadString))
    const metadata = new Map(parseNewDataArrayString(state.newReportMetadataString))
    const userIndex = state.newReportUserIndexString.split(",")

    console.log("Payload : ", payload);
    console.log("Metadata : ", metadata);
    console.log("User Index : ", userIndex);
    
    const obj = Object.fromEntries(metadata)

    console.log("Obj metadata : ", obj);
    
    obj.payload = Object.fromEntries(payload)
    obj.userIndex = userIndex
    obj.createdOn = firebase.firestore.FieldValue.serverTimestamp()

    console.log("Obj payload, userIndex, createdOn : ", obj);

    try {
      const sponsorReportsRef = firestore.collection("sponsors").doc(currentSponsor.sponsorID).collection("reports");

      dispatch({type: "set-network-request-loading", payload: true});

      sponsorReportsRef.add(obj)
        .then((docRef) => {

          // Add to user reportList
          userIndex.forEach(userID => {
          
            const userRef = firestore.collection("users").doc(userID);
      
            userRef.update({
              reportList: firebase.firestore.FieldValue.arrayUnion(docRef.id)
            })
            .catch(error => dispatch({type: "set-message", payload: error.message}));
          })

        })
        .then(() => {
          dispatch({type: "set-network-request-loading", payload: false});
          dispatch({type: "set-message", payload: "Success"});
        })
        .catch(error => dispatch({type: "set-message", payload: error.message}));
  
    } catch (e) {
      dispatch({type: "set-message", payload: e.message});
    }
  }

  async function getAllSponsors(){
    const sponsorsRef = firestore.collection("sponsors");
    var reports = []

    const newState = []
    await sponsorsRef.get().then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        console.log('doc : ', doc.data())
        await sponsorsRef.doc(doc.data().sponsorID).collection('reports').get().then((q) => {
          q.forEach((d) => {
            console.log('d : ', d.id)
            var reportDataToPush = d.data()
            reportDataToPush.uid = d.id
            reports.push(reportDataToPush)
          })
        }).then(() => {
          console.log('reports : ', reports)
          newState.push({...doc.data(), reports}) 
          reports = []
          console.log('newState intermediate : ', newState)
        }).then(() => {
          console.log('final newState : ', newState)
          dispatch({type: 'set-all-sponsors', payload: newState})
        })
      })
    })
  }
  useEffect(getAllSponsors, [])

  // cutoff
  function addNewUserToDatabase() {
    console.log('newUserSponsorList : ', state.newUserSponsorList)
    console.log('newUserReportList : ', state.newUserReportList)
    const sponsorIndex = new Map(parseNewDataArrayString(state.newUserSponsorList))
    const reportIndex = new Map(parseNewDataArrayString(state.newUserReportList))

    const preloadedNewUser = {
      createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      email: state.newUserEmail,
      reportList: [],
      sponsorList: []
    };

    try {
      const newusersRef = firestore.collection("newUsers").doc(state.newUserEmail);

      dispatch({type: "set-network-request-loading", payload: true});

      newusersRef.set(preloadedNewUser)
        .then(() => {
          dispatch({type: "set-network-request-loading", payload: false});
          dispatch({type: "set-message", payload: "Success"});
          dispatch({type: 'set-new-user-email', payload: ''})
        })
        .catch(error => dispatch({type: "set-message", payload: error.message}));


      // Add sponsor data
      const sponsorArray = []
      dispatch({type: "set-network-request-loading", payload: true});
      sponsorIndex.forEach((val, key) => {
        console.log(key)

        sponsorArray.push({roleForSponsor: val, sponsorID: key})

        newusersRef.update({
          sponsorList: sponsorArray
        })
        .then(() => {
          dispatch({type: "set-network-request-loading", payload: false});
          dispatch({type: "set-message", payload: "Success"});
          dispatch({type: 'set-new-user-sponsor-list', payload: ''})
        }).catch(e => {
          dispatch({type: "set-message", payload: e.message})
        })

        //Add report data
        const reportArray = []

        dispatch({type: "set-network-request-loading", payload: true});
        reportIndex.forEach((val, key) => {
          reportArray.push({reportUID: key, sponsorID: val})

          newusersRef.update({
            reportList: reportArray
          })
          .then(() => {
            dispatch({type: "set-network-request-loading", payload: false});
            dispatch({type: "set-message", payload: "Success"});
            dispatch({type: 'set-new-user-report-list', payload: ''})
          })
          .catch(e => {
            dispatch({type: "set-message", payload: e.message})
          })
        })

      })
    } catch (e) {dispatch({type: "set-message", payload: e.message});}
  }
  //cutoff

  function setAdminUser(uid) {
    fetchJSON("set-user-admin", { userUID: uid, setAs: true })
      .then(message => dispatch({type: "set-message", payload: message}))
      .catch(e => dispatch({type: "set-message", payload: e.message}));
  }

  function removeAdminUser(uid) {
    if (uid === userData.uid) {
      dispatch({type: "set-message", payload: "Cannot remove yourself as admin"})
      return;
    } 
    fetchJSON("set-user-admin", { userUID: uid, setAs: false })
      .then(message => dispatch({type: "set-message", payload: message}))
      .catch(e => dispatch({type: "set-message", payload: e.message}));
  }

  function checkAdminUser(uid) {
    fetchJSON("check-user-admin", { userUID: uid })
      .then(res => JSON.parse(res))
      .then(results => dispatch({type: "set-message", payload: results.message}))
      .catch(e => dispatch({type: "set-message", payload: e.message}));
  }
  

  let tabClass = "fuel-bg-teal-lightest inline-block py-2 px-4 text-gray-500 hover:text-gray-800 font-semibold";
  let activeClass = "fuel-bg-teal-lightest inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-700 font-semibold";

  return (
    <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
      <div className="flex flex-wrap flex-col space-y-4 divide-y-2">
        <ul className="flex border-b">
          <li className="-mb-px mr-1">
            <NavLink 
              exact to="/admin" 
              className={tabClass}
              activeClassName={activeClass}>
              Sponsor
              </NavLink>
          </li>
          <li className="-mb-px mr-1">
            <NavLink 
              exact to="/admin/global" 
              className={tabClass}
              activeClassName={activeClass}>
              Global
              </NavLink>
          </li>
        </ul>

        <div className="p-2">
          <p>Is making a network request: {`${state.networkRequestLoading}`}</p>
          <p>{state.message}</p>
        </div>

        <Switch>
          <Route exact path="/admin">
            <SponsorAdmin 
              state={state} 
              dispatch={dispatch}
              currentSponsorMetadata={currentSponsorMetadata}
              addReportToSponsor={addReportToSponsor}
              parseNewDataArrayString={parseNewDataArrayString}
            />
          </Route>
          <Route exact path="/admin/global">
            <GlobalAdmin 
              state={state} 
              dispatch={dispatch}
              addSponsorToDatabase={addSponsorToDatabase}
              parseNewDataArrayString={parseNewDataArrayString}
              setAdminUser={setAdminUser}
              removeAdminUser={removeAdminUser}
              checkAdminUser={checkAdminUser}
              addNewUserToDatabase={addNewUserToDatabase}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export async function removeSponsorFromUser(userUID, sponsorID, dispatch) {

  // Remove sponsor from user data
  const userDataRef = firestore.collection("users").doc(userUID);
  const removeSponsorFromUserDataPromise = userDataRef
    .update({sponsorList: firebase.firestore.FieldValue.arrayRemove(sponsorID)})
    .then(() => console.log("Success removing sponsor from user data"))
    .catch(e => dispatch({type: "set-message", payload: e.message}))

  // Remove user from sponsor data
  const sponsorRef = firestore.collection("sponsors").doc(sponsorID);

  const removeUserFromSponsorDataPromise = sponsorRef
    .update({ 
      [`userIndex.${userUID}`]: firebase.firestore.FieldValue.delete()
    })
    .then(() => console.log("Success removing user from sponsor data"))
    .catch(e => dispatch({type: "set-message", payload: e.message}))
  
  Promise.all([removeSponsorFromUserDataPromise, removeUserFromSponsorDataPromise])
}