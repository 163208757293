import { auth, functionsURL } from "../firebase";


export async function fetchJSON(routeToData, requestPayloadJSON) {
  
  const route = functionsURL + routeToData;

  console.log("ROUTE : ", route);

  // Token used server side to authenticate requests
  const token = await auth.currentUser.getIdToken();

  const response = await fetch(route, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(requestPayloadJSON)
  });

  // return response.json();
  return response.text();
}

