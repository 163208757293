import React from "react";
import PropTypes from 'prop-types';

Modal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
};

export default function Modal({ setShowModal, modalData }) {
  
  return (
      // Modal Start
      <div className="fixed z-50 inset-0 overflow-y-auto" id="modal">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-black opacity-50"></div>
          </div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            id="modal-body"
            className="inline-block align-bottom bg-white rounded-lg px-9 py-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-12 max-w-7xl"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <iframe 
              title="Monday Form Data"
              src={modalData}
              style={{border:0, height:"45rem", width:"100%"}}
            />
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      </div>
  );

}

