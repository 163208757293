import React, { 
  useState, 
  useEffect, 
  useContext 
} from "react";
import PropTypes from 'prop-types';
import { firestore } from '../../firebase';
import { UserContext } from '../../UserContext';
import useSponsor from '../../hooks/useSponsor';
import ContactCard from './ContactCard';
import ContactFAQ from './ContactFAQ';
import ContactForm from './ContactForm';

ContactContainer.propTypes = {
  hasSponsors: PropTypes.bool
}

export default function ContactContainer({ hasSponsors }) {
  
  // State
  const [ faqContent, setFAQContent ] = useState([]);
  const [ fuelContactData, setFuelContactData ] = useState({});
  const [ firstNameValue, setFirstNameValue ] = useState("");
  const [ lastNameValue, setLastNameValue ] = useState("");
  const [ descriptionValue, setDescriptionValue ] = useState("");
  const [ studyValue, setStudyValue ] = useState("");
  const [ formSubmitted, setFormSubmitted ] = useState(false);

  // Custom Hooks
  const { userData } = useContext(UserContext);
  const { 
    sponsorList,
    currentSponsorMetadata, 
  } = useSponsor(userData);

  // Fetches database data at the /metadata location
  useEffect(() => {
    const metaDataRef = firestore.collection("admin").doc("metadata");
    
    // Gets FAQ content
    metaDataRef.get().then(docSnap => {
      setFAQContent(docSnap.data().faq)
    });

    // Gets FUEL Staff data
    if (currentSponsorMetadata && currentSponsorMetadata.fuelStaffContact) {
      metaDataRef.collection("fuelStaff").doc(currentSponsorMetadata.fuelStaffContact).get().then(docSnap => {
        setFuelContactData(docSnap.data())
      });
    }
  }, [ currentSponsorMetadata ]);

  // This automatically loads the current study as the dropdown value
  useEffect(() => {
    currentSponsorMetadata && setStudyValue(currentSponsorMetadata.sponsorName)
  }, [ currentSponsorMetadata ]);

  /**
   * Handles contact form changes
   * @param {object} e Event
   */
  function onFormChange(e) {
    const fieldID = e.target.id;
    switch (fieldID) {
      case "first_name": 
        setFirstNameValue(e.target.value);
        break;
      case "last_name": 
        setLastNameValue(e.target.value);
        break;
      case "description": 
        setDescriptionValue(e.target.value);
        break;
      case "study": 
        setStudyValue(e.target.value);
        break;
      default: 
        throw new Error("There was a problem");
    }
  }

  /**
   * Handles form submit
   * Sends form via email and sets submitted state
   * @param {object} e Event
   */
  function onFormSubmit(e) {
    const formData = {
      firstNameValue,
      lastNameValue,
      descriptionValue,
      studyValue,
    };

    //todo Need to send this via email
    console.log(formData);

    setFormSubmitted(true);
  }

  return (
    <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
      <div className="lg:grid grid-flow-row grid-rows-2-minmax-auto grid-cols-3 gap-9">
        
        {/* Form */}
        {/* <ContactForm 
          onFormChange={onFormChange}
          onFormSubmit={onFormSubmit}
          sponsorList={sponsorList}
          studyValue={studyValue}
          formSubmitted={formSubmitted}
          hasSponsors={hasSponsors}
        /> */}

        {/* Contact Card */}
        { hasSponsors && <ContactCard fuelContactData={fuelContactData} /> }

        {/* FAQ */}
        {/* { hasSponsors && 
          <div className="row-span-2 col-span-2">
            <h3 className="mb-6 font-serif text-xl md:text-2xl leading-6 text-gray-900">
              FAQ
            </h3>
            <div className="space-y-6 w-full">

              <ContactFAQ 
                faqContent={faqContent}
              />

            </div>
          </div>
        } */}
      </div>
    </div>
  );
}
