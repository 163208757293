import React, { 
  useState,
} from 'react';
import PropTypes from 'prop-types';
import AdminSection from './AdminSection';

GlobalAdmin.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  addSponsorToDatabase: PropTypes.func,
  parseNewDataArrayString: PropTypes.func,
  setAdminUser: PropTypes.func,
  removeAdminUser: PropTypes.func,
  checkAdminUser: PropTypes.func,
  addNewUserToDatabase: PropTypes.func,
}

export default function GlobalAdmin({
  dispatch,
  state,
  addSponsorToDatabase,
  parseNewDataArrayString,
  setAdminUser,
  removeAdminUser,
  checkAdminUser,
  addNewUserToDatabase,
}) {

  const [ adminUserInput, setAdminUserInput ] = useState("");
  const [ checkAdminUserInput, setCheckAdminUserInput ] = useState("");
  const [ removeAdminUserInput, setRemoveAdminUserInput ] = useState("");

  // const [ newUserEmail, setNewUserEmail ] = useState("");
  // const [ newUserSponsorList, setNewUserSponsorList ] = useState("");
  // const [ newUserReportList, setNewUserReportList ] = useState("");


  return (
    <div>
      <h2 className="mb-4 font-serif text-xl md:text-3xl leading-6 text-gray-900">Global Admin</h2>

      <AdminSection header="All Users">
        <div>
        <u><strong><p>All Users</p></strong></u>
        <ul>
          {state.allUsers.map((user, i) => {
            return (
              <li key={i}>
                <strong><p>{user.displayName}</p></strong> 
                <p>{user.uid}</p>
              </li>
            )
          })}
        </ul>
        <br/>
        <ul>
          {state.allSponsors.map((sponsor, i) => {
            return (
              <li key={i}>
                <strong><p>{sponsor.sponsorID}</p></strong> 
                <ul>
                {sponsor.reports.map((report, ii) => {
                  return (
                  <li key={ii}>
                    {report.reportID} : {report.uid}
                  </li>
                  )
                })}
                </ul>
              </li>
            )
          })}
        </ul>
        <br/>
        <u><strong><p>Add User</p></strong></u>
        <div className="mt-2">
            <span>User Email: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-user-email", payload: e.target.value})}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="email@email.com"/>
          </div>
          <div className="mt-2">
            <span>Sponsor List: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-user-sponsor-list", payload: e.target.value})}
              className="shadow-sm mr-3 w-full sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="bunny~site;tree~sponsor"/>
            <span><em>Here is the signature when adding a new user: sponsorID~roleForSponsor;sponsorID~roleForSponsor</em></span>
            <br /><pre style={{color: "blue"}}>{parseNewDataArrayString(state.newUserSponsorList).map((arr, i) => {
              return <span key={i}>{arr[0]}-{arr[1]}<br /></span>
            })}</pre>
          </div>
          <div className="mt-2">
            <span>Report List: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-user-report-list", payload: e.target.value})}
              className="shadow-sm mr-3 w-full sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="WfEsnBNAgfoTy4cTZ5z8~bunny;2YZoOiXsLT48gSPlOCg8~tree"/>
            <span><em>Here is the signature when adding a new user: reportUID~sponsorID;reportUID~sponsorID</em></span>
            <br /><pre style={{color: "blue"}}>{parseNewDataArrayString(state.newUserReportList).map((arr, i) => {
              return <span key={i}>{arr[0]}-{arr[1]}<br /></span>
            })}</pre>
          </div>
          <span>Create New User: </span>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={addNewUserToDatabase}>Process</button>
          </div>
      </AdminSection>

      <AdminSection header="Admin Users">
            <div>
        <div>

          <input 
            onChange={e => setAdminUserInput(e.target.value)}
            className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
            placeholder="UID of the user to set as admin" 
            value={adminUserInput}/>
        
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
            setAdminUser(adminUserInput);
            setAdminUserInput("");
            }}>Set as admin</button>

        </div>
        <div className="mt-2">

          <input 
            onChange={e => setCheckAdminUserInput(e.target.value)}
            className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
            placeholder="UID of the user to check if admin" 
            value={checkAdminUserInput}/>

          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
            checkAdminUser(checkAdminUserInput);
            setCheckAdminUserInput("");
            }}>Check if admin</button>

        </div>
        <div className="mt-2">

          <input 
            onChange={e => setRemoveAdminUserInput(e.target.value)}
            className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
            placeholder="UID of the user to remove as admin" 
            value={removeAdminUserInput}/>

          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
            removeAdminUser(removeAdminUserInput);
            setRemoveAdminUserInput("");
            }}>Remove as admin</button>

        </div>
        </div>
      </AdminSection>

      {/* Start of UI for setting up a new user */}
      {/* <AdminSection header="Set Up New User Account">
        <div>
          <div className="mt-2">
            <span>Email: </span>
            <input 
              onChange={(e) => setNewUserEmail(e.target.value)}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="jerry@fuel.dev"
              value={newUserEmail}/>
          </div>
          <div className="mt-2">
            <span>Sponsor List: </span>
            <input 
              onChange={(e) => setNewUserEmail(e.target.value)}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="jerry@fuel.dev"
              value={newUserEmail}/>
          </div>
          <div className="mt-2">
            <span>Report List: </span>
            <input 
              onChange={(e) => setNewUserEmail(e.target.value)}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="jerry@fuel.dev"
              value={newUserEmail}/>
          </div>
        </div>
      </AdminSection> */}

      <AdminSection header="New Sponsor">
        <div className="mt-2">
          <div className="mt-2">
            <span>Sponsor ID: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-sponsor-id", payload: e.target.value})}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="example: coyote"/>
          </div>
          <div className="mt-2">
            <span>Sponsor Display Name: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-sponsor-name", payload: e.target.value})}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="example: Coyote"/>
          </div>
          <div className="mt-2">
            <span>Fuel Staff Contact: </span>
            <input 
              onChange={(e) => dispatch({type: "set-fuel-staff-contact", payload: e.target.value})}
              className="shadow-sm mr-3 w-64 sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="example: jerry"/>
              <span><em>Here are the possible choices: {state.fuelStaffList.join(", ")}</em></span>
          </div>
          <div className="mt-2">
            <span>Add Users: </span>
            <input 
              onChange={(e) => dispatch({type: "set-new-sponsor-user-index", payload: e.target.value})}
              className="shadow-sm mr-3 w-full sm:text-sm border px-3 py-2 border-gray-300 rounded-md"
              placeholder="jk42IFzvSU7UCduXNgeug7osRqTx~sponsor;mQ1VMus2q2B3Pq4SEQ038fOn89C9~site"/>
            <span><em>Here is the signature when adding a new user: uid~role;uid~role</em></span>
            <br /><a className="underline" href="https://github.com/fuel-dev/derive/wiki/Admin#new-sponsor" target="_blank" rel="noreferrer">See the wiki for help</a>
            <br /><pre style={{color: "blue"}}>{parseNewDataArrayString(state.newSponsorUserIndex).map((arr, i) => {
              return <span key={i}>{arr[0]}-{arr[1]}<br /></span>
            })}</pre>
          </div>

          <span>Add Sponsor: </span>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={addSponsorToDatabase}>Process</button>
        </div>
      </AdminSection>

    </div>
  )
}