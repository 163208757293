import React, { 
  useState, 
  useEffect, 
} from "react";
import PropTypes from "prop-types";
import { 
  useParams, 
  NavLink, 
} from 'react-router-dom';
import { ReportFavoriteStar } from './ReportFavoriteStar';

ReportDetail.propTypes = {
  fullReportList: PropTypes.array.isRequired,
  onStarChange: PropTypes.func.isRequired,
  updateReportViewTimestamp: PropTypes.func.isRequired,
};

export function ReportDetail({ 
  fullReportList, 
  onStarChange, 
  updateReportViewTimestamp, 
}) {

  // fixme when on detail page and the sponsor is changed, should kick back to /reports

  // fixme when report is added, have no "last viewed" date

  const initialMetadata = { 
    isFavorite: false,
    lastViewed: "",
    reportTitle: "",
    status: "",
    reportUID: "",
    payload: {},
  };

  const { reportID } = useParams();
  const [ loading, setLoading ] = useState(true);
  const [ reportMetadata, setReportMetadata ] = useState(initialMetadata);

  useEffect(() =>  {

    const viewingReport = fullReportList.find(report => report.reportID === reportID);
    
    if (viewingReport !== undefined) {
      setReportMetadata(viewingReport);
      updateReportViewTimestamp(viewingReport.reportUID);
      setLoading(false)
    }

  }, [fullReportList, reportID, updateReportViewTimestamp]);

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  if (loading) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className="md:-mt-4">
        <NavLink
          exact
          to={`/reports`}
          className="inline-flex items-center text-xs text-gray-500 hover:text-gray-900 mb-3"
      >
        <span>
          <svg
            className="h-3 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </span>
          Back to Reports
        </NavLink>
        <div id="report-container" className="bg-white rounded-lg shadow px-4 py-5 sm:px-6">
          <div id="report-header" className="flex items-start justify-between flex-nowrap">
            <div className="flex flex-nowrap items-start mr-4">
              <button type="button" className="mt-0.5 mr-2 md:mr-3">
                <ReportFavoriteStar 
                  reportUID={reportMetadata.reportUID}
                  isFavorite={reportMetadata.isFavorite}
                  onStarChange={onStarChange}
                />
              </button>
              <div className="flex flex-col">
                <div className="flex flex-wrap items-center">
                  <h3 className="inline-flex text-lg md:text-xl leading-6 text-gray-900 mr-2 md:mr-3">
                    {reportMetadata.reportTitle}
                  </h3>
                  <span 
                    className={reportMetadata.status === "Live" ? 
                      "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800":
                      "inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                    }
                  >
                    {reportMetadata.status}
                  </span>
                </div>
                <span className="text-xs text-gray-400 mt-1">
                  Last Viewed: {reportMetadata.lastViewed}
                </span>
              </div>
            </div>
            
            {/* <div className="flex items-center"> */}
              
              {/* <a href={reportMetadata.payload.linkToReport} rel="noreferrer" target="_blank"> */}
              
              {/* <button
                onClick={() => printIframe('report-iframe')}
                id="print-button"
                type="button"
                className="flex-shrink-0 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Print
                <svg
                  className="ml-1 w-3.5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  /> 
                </svg>
              </button>
            </div> */}
          </div>
          <div className="lg:grid grid-flow-row grid-rows-2-minmax-auto gap-24 mb-6 lg:mb-0 mt-12">

            <DataStudioReport 
              embedURL={reportMetadata.payload.embedURL}
              reportID={reportMetadata.reportUID}
              displayName='Hello'
            />

            {/* Display for Large/Old Reports */}
            {/*<div className="text-center py-36 px-8">
              <h1 className="font-serif text-2xl md:text-3xl leading-6 text-gray-900">Your report is too large to visualize</h1>
              <p className="mx-auto mt-2 mb-8 text-sm md:text-base text-gray-500" style={{ maxWidth: '480px' }}>Our system has a 20-column limit for generating reports.</p>
              <button
                type="button"
                className="relative w-full md:w-auto inline-flex items-center justify-center px-8 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Download in CSV format
              </button>
            </div>*/}

            {/* Example */}
            {/* <div id="report-body" className="lg:grid grid-flow-row grid-rows-2-minmax-auto grid-cols-3 gap-24 mb-6 lg:mb-0 mt-12">
              
              <div className="row-span-1 col-span-3 mb-6 md:mb-12 lg:mb-0">
                <img
                  className="w-full m-auto"
                  src="/images/chart-area.png"
                  alt="chart example"
                />
              </div>
              <div className="row-span-2 col-span-1 mb-6 md:mb-12 lg:mb-0">
                <img
                  className="w-full m-auto max-w-xs"
                  src="/images/pie-chart.png"
                  alt="chart example"
                />
              </div>
              <div className="row-span-2 col-span-2">
                <img
                  className="w-full m-auto"
                  src="/images/bar-chart.png"
                  alt="chart example"
                />
              </div>
            </div>  */}
          </div>
        </div>
      </div>
    );
  }
}

DataStudioReport.propTypes = {
  embedURL: PropTypes.string,
  reportID: PropTypes.string,
};

function DataStudioReport(props) {
  return (
    <div
      className="w-full h-screen"
      >
      <iframe
        id="report-iframe"
        title={props.reportID}
        className="w-full h-full"
        src={props.embedURL}
        frameBorder="0"
        style={{border:0}}
        allowFullScreen
      />
    </div>
  ); 
}