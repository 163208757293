import React from "react";
import Login from "./Login";
import { googleSignIn } from '../firebase';

export default function LoginContainer() {
  return (
    <div className="App h-screen flex overflow-hidden fuel-bg-teal-lightest">
      <main
        className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
        tabIndex="0"
      >
        <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
          <Login googleSignIn={googleSignIn}/>
        </div>
      </main>
    </div>
  );
}