import React from "react";
import PropTypes from "prop-types";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ReportFavoriteStar } from './ReportFavoriteStar';

ReportListContainer.propTypes = {
  fullReportList: PropTypes.arrayOf(PropTypes.object),
  onStarChange: PropTypes.func.isRequired,
  searchVal: PropTypes.string,
  filterOn: PropTypes.string,
};

export function ReportListContainer({ 
  fullReportList, 
  searchVal, 
  onStarChange, 
  filterOn, 
}) {

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto -mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <ReportListHeader />

              <tbody className="bg-white divide-y divide-gray-200">

                {
                  fullReportList
                    // This is the filtering for the search input
                    .filter(report => searchVal === "" ? true : report.reportTitle.toUpperCase().startsWith(searchVal))
                    .filter(report => {
                      if (filterOn === "all") {
                        return report;
                      } else if (filterOn === "fav") {
                        if (report.isFavorite) {
                          return report;
                        }
                      }
                      return false;
                    })
                    .map((report, i) => {
                      return (
                        <ReportListRow
                          key={i}
                          reportUID={report.reportUID}
                          reportTitle={report.reportTitle}
                          lastViewed={report.lastViewed}
                          isFavorite={report.isFavorite}
                          reportID={report.reportID}
                          onStarChange={onStarChange}
                          status={report.status}
                        />
                      );
                    })
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

ReportListRow.propTypes = {
  reportUID: PropTypes.string.isRequired,
  reportTitle: PropTypes.string.isRequired,
  lastViewed: PropTypes.string.isRequired,
  reportID: PropTypes.string.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onStarChange: PropTypes.func.isRequired,
  status: PropTypes.string,
};

function ReportListRow({ 
  reportUID, 
  reportTitle, 
  lastViewed, 
  reportID, 
  isFavorite, 
  onStarChange, 
  status, 
}) {
  const { url } = useRouteMatch();

  // Format date
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateObject = new Date(lastViewed);
  const formattedDate = dateObject.toLocaleDateString('en-US', options);

  return (
    <tr className={ isFavorite ? "" : "" }>
      <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">

        <ReportFavoriteStar 
          reportUID={reportUID}
          isFavorite={isFavorite}
          onStarChange={onStarChange}
        />

      </td>
      <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
        <NavLink
          className="hover:underline"
          exact
          to={`${url}/${reportID}`}
        >
          {reportTitle}
        </NavLink>
      </td>
      <td className="hidden sm:table-cell tpx-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span 
          className={status === "Live" ? 
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800":
            "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
          }
        >
          {status}
        </span>
      </td>
      <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {formattedDate}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <NavLink
          exact
          to={`${url}/${reportID}`}
          className="group fuel-text-teal hover:text-gray-900"
        >
          View
          <span className="ml-1 hidden sm:inline-block opacity-0 group-hover:opacity-100">
            →
          </span>
        </NavLink>
      </td>
    </tr>
  );
}

// TODO Make sorting by data and title work
function ReportListHeader() {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="w-9">
          <span className="sr-only">Favorite</span>
        </th>
        <th
          scope="col"
          className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Title
        </th>
        <th
          scope="col"
          className="hidden sm:table-cell px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          Status
        </th>
        <th
          scope="col"
          className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        >
          <span className="text-gray-800 font-semibold">Last viewed</span>{" "}
          {/* <span className="text-gray-400">▼</span>  */}
        </th>
        <th scope="col" className="relative px-3 lg:px-6 py-3">
          <span className="sr-only">Link</span>
        </th>
      </tr>
    </thead>
  );
}

