import React from "react";
import PropTypes from 'prop-types';

ContactCard.propTypes = {
  fuelContactData: PropTypes.object.isRequired,
};

export default function ContactCard({ fuelContactData }) {
  return (
    <div className="row-span-2 col-span-1 self-start mb-6 lg:mb-0 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-300">
      <div className="flex-1 flex flex-col p-8 fuel-bg-teal-light">
        <img
          className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
          src={fuelContactData.photoSRC}
          alt=""
        />
        <h3 className="mt-6 text-gray-900 text-md font-medium">{fuelContactData.displayName}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-gray-500 text-sm">
            {fuelContactData.title}
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <a
              href={`mailto:${fuelContactData.email}?subject=Request from Derive`}
              target='_blank'
              rel='noreferrer'
              className="group relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-500 font-medium border border-transparent rounded-bl-lg hover:text-gray-900"
            >
              <svg
                className="w-5 h-5 fuel-text-teal-bright group-hover:text-teal-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <span className="ml-3">Email</span>
            </a>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <a
              href={`tel:${fuelContactData.phone}`}
              className="group relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-500 font-medium border border-transparent rounded-br-lg hover:text-gray-900"
            >
              <svg
                className="w-5 h-5 fuel-text-teal-bright group-hover:text-teal-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
              <span className="ml-3">Call</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}