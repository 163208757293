import React from "react";
import { PropTypes } from "prop-types";

const linksMap = new Map([
  ["new-user-request", 'https://forms.monday.com/forms/embed/4a91fc0ed78ae0b8ec30a5b93a09775b?r=use1'],
  ["new-project-request", 'https://forms.monday.com/forms/embed/3fccc5d7aaf546cbbd33f459ca71fb56?r=use1'],
  ["new-item-request", 'https://forms.monday.com/forms/embed/6fa12808fde9c70b92f445f2822866fd?r=use1'],
  ["delete-form-request", 'https://forms.monday.com/forms/embed/064354df8040ceab730ee30e336fe77d?r=use1'],
  ["users-tracker", '/'],
  ["projects-tracker", '/'],
  ["items-tracker", '/'],
  ["form-history-tracker", '/'],
]);


QuickLink.propTypes = {
  drawPath: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  task: PropTypes.string.isRequired,
};

export default function QuickLink({ drawPath, text, variant, task }) {

  let linkClass, svgClass;
  if (variant === "navbar") {
    linkClass = "group flex items-center w-auto hover:text-white";
    svgClass = "h-5 mr-2 text-teal-600 group-hover:text-teal-400";
  } else if (variant === "dashboard") {
    linkClass = "group flex items-center w-auto hover:text-gray-900";
    svgClass = "h-5 mr-2 fuel-text-teal-bright group-hover:text-teal-700";
  }

  return (
    <a
      href={linksMap.get(task)}
      className={linkClass}
      target="_blank"
      rel="noreferrer"
    >
      <svg
        className={svgClass}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d={drawPath}
        />
      </svg>
      {text}
    </a>
  );
}