import React, { 
  useContext,
} from "react";
import { 
  Switch,
} from "react-router-dom";
import "./App.css";
import { 
  UserContext,
} from './UserContext';
import { 
  PrivateRoute, 
  PublicRoute, 
} from './components/CustomRoutes';
import LoginContainer from './components/LoginContainer';
import DefaultContainer from './components/DefaultContainer';
import NewUserSetup from './components/NewUserSetup';

function App() {
  const { 
    userLoginData, 
    userData, 
    userLoading, 
    logout, 
    authenticated,
    newUserSetup,
  } = useContext(UserContext);

  // For Development Security
  // Only users with fuel.dev email's can get into app
  let emailDomain = userLoginData && userLoginData.email.split('@').pop();
  if ((emailDomain && (emailDomain !== "fuel.dev" && emailDomain !== "derive.dev" && emailDomain !== 'derive.one')) && (window.location.hostname !== "localhost")) {
    return <><p>Restricted</p><button onClick={logout}>Log Out</button></>;
  }

  /**
   * Routing at App Level
   * TL;DR The user only passes past the App component if they are authenticated=true and have a user account set up.
   * 
   * During authentication check, authentication will be null; After results, authentication will be TRUE/FALSE
   * If the user is authenticated and user login data fetched and userData is null (meaning there is no account set up on firestore database),
   *  then direct to screen specifying no user account
   * If the user is authenticated=false they will be redirected by the PrivateRoute component back to /login
   * If user is authenticated=true, they will pass through the PrivateRoute component where all other routes are handled
   */
  if ( authenticated === null ) {
    return <></>;
  } else if (newUserSetup) {
    return (<NewUserSetup />)
  } else if (authenticated && !userLoading && userData === false) {
    // if user account is not set up
    return (
      <>
        <h1>It looks like an account has not been set up for this user.</h1>
        <button onClick={logout}>Log Out</button>
      </>
    );
  } else {
    return (
      <Switch>

        {/* If unauthenticated, user will always be directed back to login */}
        <PublicRoute exact path="/login" restricted={true} component={LoginContainer} />

        {/* All private sponsor routes protected behind DefaultContainer */}
        <PrivateRoute path="*"  component={DefaultContainer} />

      </Switch>
    );
  }
}

export default App;
