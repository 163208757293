import { useEffect, useState, useCallback } from 'react';
import { firestore } from '../firebase';
import { firebase } from '@firebase/app';

export default function useReportList(userData) {

  const [ reportsMetadata, setReportsMetadata ] = useState([]);
  const [ loadingReportList, setLoadingReportList ] = useState(false);

  const getReportsMetadata = useCallback(async function() {
      
    // User report UID list
    const userReportIDList = userData.reportList;

    // Must check if userReportList is empty before querying against it per the Firestore query rules
    if (userReportIDList.length > 0 && userData.currentSponsorID.length > 0) {
      console.log('Entered if')
      // Sponsor Report UID List
      return await firestore.collection("sponsors")
        .doc(userData.currentSponsorID)
        .collection("reports")
        // Only returns reports that list the user in the reports userIndex
        .where("userIndex", "array-contains", userData.uid)
        .get()
        .then(querySnapshot => {
          console.log('Query Snapshot : ', querySnapshot)
          // Each function stored into a promise and pushed to array to be resolved at one time later
          const promiseArr = [];
          querySnapshot.forEach(docSnap => {
            console.log('In for each')
            promiseArr.push(Promise.resolve(fetchAndFormatReportData(docSnap)));
          });

          return promiseArr;

          async function fetchAndFormatReportData(reportDocSnap) {
            console.log('fetchAndFormatReportData : ', reportDocSnap)

            // If the user report list includes the document ID 
            // Users must have the report document id in their reportIDList to see the report
            if (userReportIDList.includes(reportDocSnap.id)) {
              const reportData = reportDocSnap.data();

              // Separates the userIndex from the rest of the properties
              const {userIndex, ...rest} = reportData;

              // this prevents users who are not on the reports userIndex from viewing the report
              if (!userIndex.includes(userData.uid)) {
                console.debug(`User ${userData.displayName} is not on the ${rest.reportTitle} report userIndex list for sponsor ${userData.currentSponsorID}; \nreport: ${rest.reportID}; \nuserUID: ${userData.uid}`);
  
                return;
              }

              const userReportsRef = firestore
                .collection("users")
                .doc(userData.uid)
                .collection("user_reports")
                .doc(reportDocSnap.id);

              // Gets the user report data
              // If user report data does not exist it creates the document and fetches it again
              const userReportData = await userReportsRef.get()
                .then(doc => {
                  if (!doc.exists) {

                    // Create the new user report data
                    // then gets the data from the ref again after that data has been stored
                    return userReportsRef.set({
                      lastViewed: firebase.firestore.FieldValue.serverTimestamp(),
                      isFavorite: false,
                    })
                      // Need to fetch the data from the database again, using a firebase timestamp requires this
                      .then(() => userReportsRef.get().then(doc => doc.data()));
                      
                  } else {
                    return doc.data();
                  }
                });
              
              // Format date
              const options = { year: 'numeric', month: 'long', day: 'numeric' };
              const dateObject = userReportData.lastViewed.toDate();
              // const formattedDate = dateObject.toLocaleDateString('en-US', options);
              const formattedDate = dateObject.toString();
              
              return {
                ...rest, 
                lastViewed: formattedDate,
                isFavorite: userReportData.isFavorite,
                reportUID: reportDocSnap.id,
              };
            }
          }
        })
        .then( async promiseArr => {
          const allReports = await Promise.all(promiseArr);
          return allReports.filter(report => report !== undefined);
        });
    } else {
      console.log("This user has no reports");
    }
  }, [ userData ]);

  // Sets the user and sponsor report lists.
  useEffect(() => {
    if (userData) {
      console.log('in if userData')
      setLoadingReportList(true);

      getReportsMetadata()
        .then(reportData => {
          reportData === undefined ? setReportsMetadata([]) : setReportsMetadata(reportData);
        })
        .then(() => setLoadingReportList(false));

    }
  }, [ userData, getReportsMetadata ]);

  const getAuthorizedReports = useCallback(async () => {

    const reports = await getReportsMetadata();
    return reports.map(report => report.reportID);

  }, [ getReportsMetadata ]);
  
  return {
    reportsMetadata, 
    setReportsMetadata,
    loadingReportList,
    getAuthorizedReports,
  } ;
}
