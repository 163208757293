import React, { 
  useState
} from "react";
import EditUserModal from "./EditUserModal";
import { NavLink } from "react-router-dom";

const tabs = [
  { name: 'Users', href: '#', current: true },
  { name: 'Sponsors', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

  export default function AdminStaticContainer() {

    const [ showEditUserModal, setShowEditUserModal ] = useState();

    return (
      <>
        <div className="bg-white pb-2 border-b border-gray-300 sm:pb-0">
          <div className="pt-2 sm:pt-4 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-6">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current
                        ? 'border-teal-700 text-teal-800 font-semibold'
                        : 'border-transparent text-gray-400 hover:text-gray-700',
                      'whitespace-nowrap pb-3 px-1 border-b-2 text-sm'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="py-6 md:py-9 max-w-7xl mx-auto px-4 md:px-9 lg:px-12">
          <div className="flex flex-col lg:flex-row justify-between align-center mb-4 md:mb-6">
            <div className="flex mb-4 lg:mb-0">
              <h3 className="font-serif text-2xl md:text-3xl leading-6 text-gray-900 mr-4 md:mr-6 lg:mr-8">
                Users
              </h3>
              <div className="flex items-center">
                <span className="cursor-pointer inline-flex items-center px-2 py-1 rounded-full text-xs font-medium text-teal-800 mr-1" style={{ backgroundColor: "hsla(183,81%,25%,10%)" }}>
                  This Sponsor
                </span>
                <span className="cursor-pointer inline-flex items-center px-2 py-1 rounded-full text-xs font-medium text-gray-500 hover:text-gray-700">
                  All
                </span>
              </div>
            </div>
          
            
            <div className="flex items-center">
              {/* Bulk Edit Actions */}
              <div className="hidden">
                <div className="flex items-center mr-4">
                  <span className="text-xs text-gray-500">3 selected</span>
                  <span className="border-r border-gray-200 h-6 block w-2 pr-4 mr-2"></span>
                  <span className="cursor-pointer inline-flex items-center px-2 py-1 text-xs font-medium text-teal-600 hover:text-gray-700">
                    Deselect
                  </span>
                </div>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
                >
                  Bulk Edit
                </button>
              </div>
              {/* Search */}
              <div className="relative rounded-md shadow-sm flex-grow lg:flex-grow-0 lg:w-80 mr-4">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none flex-grow">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="pl-10 text-sm border px-3 py-2 border-gray-300 rounded-md w-full"
                  placeholder="Search users"
                />
              </div>
              <NavLink
                to="/admin/"
                type="button"
                className="relative inline-flex items-center px-2.5 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <svg
                  className="mr-1 sm:mr-2 w-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 10"
                  stroke="currentColor"
                >
                  <path
                    d="M5 1v4m0 0v4m0-4h4M5 5H1"
                    stroke="#0FB7AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add User
              </NavLink>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="w-9">
                          <span className="sr-only">Favorite</span>
                        </th>
                        <th
                          scope="col"
                          className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="hidden sm:table-cell px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-60"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="hidden xl:table-cell px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="text-gray-900">Last Signed In</span>{" "}
                          <span className="text-gray-400">▼</span>
                        </th>
                        <th scope="col" className="relative px-3 py-3 w-10">
                          <span className="sr-only">Link</span>
                        </th>
                        <th scope="col" className="hidden sm:table-cell relative px-3 py-3 w-9">
                          <span className="sr-only">Expand Row</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">
                          <input
                            id="selected"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-teal-600 border-gray-300 checked:bg-teal-500 rounded"
                          />
                        </td>
                        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                          Gina Meixner
                        </td>
                        <td className="hidden sm:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          gina.meixner@fuel.dev
                        </td>
                        <td className="hidden xl:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          admin
                        </td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          November 9, 2019
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <span className="group cursor-pointer fuel-text-teal hover:text-gray-900">
                            Edit
                            <span className="hidden sm:inline-block ml-1 opacity-0 group-hover:opacity-100">
                              →
                            </span>
                          </span>
                        </td>
                        <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <svg className="cursor-pointer fill-current text-gray-400 hover:text-gray-600" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.351 8.752a1.2 1.2 0 0 1 1.698 0L12 12.703l3.951-3.951a1.2 1.2 0 1 1 1.697 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.697 0l-4.8-4.8a1.2 1.2 0 0 1 0-1.697Z" fill="inherit"/>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">
                          <input
                            id="selected"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-teal-600 border-gray-300 checked:bg-teal-500 rounded"
                          />
                        </td>
                        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                          Mack Macintosh
                        </td>
                        <td className="hidden sm:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          mack.mackintosh@fuel.dev
                        </td>
                        <td className="hidden xl:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          sponsor
                        </td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          October 25, 2020
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <span className="group cursor-pointer fuel-text-teal hover:text-gray-900">
                            Edit
                            <span className="hidden sm:inline-block ml-1 opacity-0 group-hover:opacity-100">
                              →
                            </span>
                          </span>
                        </td>
                        <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <svg className="cursor-pointer fill-current text-gray-400 hover:text-gray-600" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.351 8.752a1.2 1.2 0 0 1 1.698 0L12 12.703l3.951-3.951a1.2 1.2 0 1 1 1.697 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.697 0l-4.8-4.8a1.2 1.2 0 0 1 0-1.697Z" fill="inherit"/>
                          </svg>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">
                          <input
                            id="selected"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-teal-600 border-gray-300 checked:bg-teal-500 rounded"
                          />
                        </td>
                        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                          Gigi Smith
                        </td>
                        <td className="hidden sm:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          gigi.smith@fuel.dev
                        </td>
                        <td className="hidden xl:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          user
                        </td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          August 7, 2020
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <span className="group cursor-pointer fuel-text-teal hover:text-gray-900">
                            Edit
                            <span className="hidden sm:inline-block ml-1 opacity-0 group-hover:opacity-100">
                              →
                            </span>
                          </span>
                        </td>
                        <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <svg className="cursor-pointer fill-current text-gray-400 hover:text-gray-600" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.351 8.752a1.2 1.2 0 0 1 1.698 0L12 12.703l3.951-3.951a1.2 1.2 0 1 1 1.697 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.697 0l-4.8-4.8a1.2 1.2 0 0 1 0-1.697Z" fill="inherit"/>
                          </svg>
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">
                          <input
                            id="selected"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-teal-600 border-gray-300 checked:bg-teal-500 rounded"
                          />
                        </td>
                        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                          Cody Wilke
                        </td>
                        <td className="hidden sm:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          cody.wilke@fuel.dev
                        </td>
                        <td className="hidden xl:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          <em>new</em>
                        </td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          May 9, 2020
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <span className="group cursor-pointer fuel-text-teal hover:text-gray-900">
                            Edit
                            <span className="hidden sm:inline-block ml-1 opacity-0 group-hover:opacity-100">
                              →
                            </span>
                          </span>
                        </td>
                        <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <svg className="transform rotate-180 cursor-pointer fill-current text-gray-800 hover:text-gray-600" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.351 8.752a1.2 1.2 0 0 1 1.698 0L12 12.703l3.951-3.951a1.2 1.2 0 1 1 1.697 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.697 0l-4.8-4.8a1.2 1.2 0 0 1 0-1.697Z" fill="inherit"/>
                          </svg>
                        </td>
                      </tr>
                      <tr className="bg-gray-50 align-top">
                        <td />
                        <td colSpan="1" className="p-0 px-3 lg:px-6 py-6 lg:py-10">
                          <p className="mb-4 font-serif text-xl md:text-2xl leading-6 text-gray-900">Sponsors</p>
                          <ul className="text-sm text-gray-600">
                            <li className="truncate mb-2">UOC</li>
                            <li className="truncate mb-2">Collins Center</li>
                            <li className="truncate mb-2">Mainstay Medical</li>
                            <li className="truncate mb-2">Harrison</li>
                          </ul>
                        </td>
                        <td className="sm:hidden"></td>
                        <td colSpan="2" className="hidden sm:table-cell p-0 px-3 lg:px-6 py-6 lg:py-10">
                          <p className="mb-4 font-serif text-xl md:text-2xl leading-6 text-gray-900">Reports</p>
                          <ul className="text-sm text-gray-600">
                            <li className="truncate mb-2">Ediary Compliance Report Site Level Houston</li>
                            <li className="truncate mb-2">HTP IP Usage Report Subject Level 2</li>
                            <li className="truncate mb-2">Harrison Comparison Foundation Stats</li>
                            <li className="truncate mb-2">UOC Quarterly Results</li>
                            <li className="truncate mb-2">Mainstay Management Daily Update (12/29/21)</li>
                            <li className="truncate mb-2">Collins Daily Log of Patient Check-Ins</li>
                            <li className="truncate mb-2">Subject Mismatches Report - December 21</li>
                            <li className="truncate mb-2">Mainstay Management Daily Update (12/28/21)</li>
                            <li className="truncate mb-2">Subject Mismatches Report - November 21</li>
                            <li className="truncate mb-2">Ediary Time Comparison Report</li>
                            <li className="truncate mb-2">Subject Mismatches Report - October 21</li>
                          </ul>
                        </td>
                        <td colSpan="2" className="hidden sm:table-cell xl:hidden"></td>
                        <td colSpan="3" className="hidden xl:table-cell p-0 px-3 lg:px-6 py-6 lg:py-10">
                          <div className="block float-right w-5/6 xl:pr-6">
                            <div className="flex justify-between w-full items-center mb-5">
                              <label htmlFor="location" className="text-sm font-medium text-gray-700">Sort Lists By:</label>
                              <select
                                id="list-sort"
                                name="list-sort"
                                className="cursor-pointer mt-1 block pl-3 pr-6 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                defaultValue="Last Viewed"
                              >
                                <option>Alphabetical</option>
                                <option>Date Created</option>
                                <option>Last Viewed</option>
                              </select>
                            </div>
                            <div className="flex flex-col w-full bg-gray-100 border border-gray-300 rounded-md py-6 px-6">
                              <button
                                onClick={() => {
                                  setShowEditUserModal(true);
                                }}
                                type="button"
                                className="mb-3 text-center px-2.5 py-2.5 border border-transparent shadow-sm rounded-md text-sm text-white fuel-bg-teal hover:fuel-bg-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2"
                              >
                                Edit User
                              </button>
                              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button type="button" className="w-full justify-center relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                                  Export CSV
                                </button>
                                <span className="-ml-px relative block">
                                  <button type="button" className="relative inline-flex items-center px-2.5 py-2.5 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" id="option-menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span className="sr-only">Open options</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                  </button>

                                  {/*
                                    Dropdown menu, show/hide based on menu state.

                                    Entering: "transition ease-out duration-100"
                                      From: "transform opacity-0 scale-95"
                                      To: "transform opacity-100 scale-100"
                                    Leaving: "transition ease-in duration-75"
                                      From: "transform opacity-100 scale-100"
                                      To: "transform opacity-0 scale-95"
                                  */}
                                  <div className="hidden origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="option-menu-button" tabIndex="-1">
                                    <div className="py-1" role="none">
                                      {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
                                      <a href="/admin/" className="hover:bg-gray-50 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-0">
                                        Export Users
                                      </a>

                                      <a href="/admin/" className="hover:bg-gray-50 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-1">
                                        Export Sponsors
                                      </a>

                                      <a href="/admin/" className="hover:bg-gray-50 text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="option-menu-item-2">
                                        Export All Data
                                      </a>
                                    </div>
                                  </div>
                                </span>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-3 lg:px-5 py-4 whitespace-nowrap text-right text-sm">
                          <input
                            id="selected"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-teal-600 border-gray-300 checked:bg-teal-500 rounded"
                          />
                        </td>
                        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
                          Jennifer Lyden
                        </td>
                        <td className="hidden sm:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          jennifer.lyden@fuel.dev
                        </td>
                        <td className="hidden xl:table-cell px-3 lg:px-6 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                          user
                        </td>
                        <td className="hidden lg:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          February 29, 2020
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <span className="group cursor-pointer fuel-text-teal hover:text-gray-900">
                            Edit
                            <span className="hidden sm:inline-block ml-1 opacity-0 group-hover:opacity-100">
                              →
                            </span>
                          </span>
                        </td>
                        <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <svg className="cursor-pointer fill-current text-gray-400 hover:text-gray-600" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.351 8.752a1.2 1.2 0 0 1 1.698 0L12 12.703l3.951-3.951a1.2 1.2 0 1 1 1.697 1.697l-4.8 4.8a1.2 1.2 0 0 1-1.697 0l-4.8-4.8a1.2 1.2 0 0 1 0-1.697Z" fill="inherit"/>
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-between mt-4 md:mt-5">
                  <p className="text-sm text-gray-500"><span className="font-medium text-gray-700">138</span> results</p>
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="cursor-default inline-flex items-center px-2.5 py-1.5 border border-gray-200 text-xs font-medium rounded text-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showEditUserModal && <EditUserModal setShowEditUserModal={setShowEditUserModal} />}
      </>
      );
}