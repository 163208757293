import React, { 
    useState, 
    useEffect, 
    useContext, 
    useCallback, 
  } from "react";
import { firebase } from '@firebase/app';
import { firestore } from "../firebase";
import useReportList from '../hooks/useReportList';
import { UserContext } from '../UserContext';
import { ReportDetail } from './ReportDetail';
import PropTypes from "prop-types";
import { NavLink, Switch, Route, useRouteMatch } from "react-router-dom";

export function RecentlyViewedReports() {  

    const { path } = useRouteMatch();


    const { userData } = useContext(UserContext);
    const { reportsMetadata } = useReportList(userData);
  
    const [fullReportList, setFullReportList] = useState([]);

    /**
    * When a report is viewed, update the timestamp in the database for the current user
    */
    const updateReportViewTimestamp = useCallback((reportID) => {
        const reportRef = firestore.collection("users").doc(userData.uid).collection("user_reports").doc(reportID);
        reportRef.update({lastViewed: firebase.firestore.FieldValue.serverTimestamp()});
    }, [ userData.uid ]);

    // Sets the initial state of the fullReportList once the report list has loaded
    useEffect(() => {
        // console.log(reportsMetadata.slice(reportsMetadata.length - 3));
        const s = reportsMetadata.sort((a, b) => Date.parse(b.lastViewed) - Date.parse(a.lastViewed));        
        setFullReportList(s.slice(0, 3));
    }, [ reportsMetadata ]);

    function onStarChange(reportUID) {

        const arr = fullReportList.map(report => {
          if(report.reportUID === reportUID) {
            const newReport = {...report};
            newReport.isFavorite = !report.isFavorite;
            return newReport;
          }
          return report;
        });
        setFullReportList(arr);
      }

  return (
      <>
        <Route exact path={path}>

            <div className="bg-white rounded-lg shadow row-span-2 col-span-3 px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between flex-wrap sm:flex-nowrap mb-6">
                    <h3 className="font-serif text-xl md:text-2xl leading-6 text-gray-900">
                        Recently Viewed Reports
                    </h3>
                    <NavLink
                    to={`/reports`}
                    className="flex-shrink-0 group inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        View All
                        <svg
                            className="ml-1 sm:ml-2 w-3"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    </NavLink>
                </div>

                <div className="overflow-hidden border border-gray-200 rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-3 md:px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Title
                                </th>
                                <th
                                    scope="col"
                                    className="hidden sm:table-cell px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Status
                                </th>
                                <th scope="col" className="relative px-3 lg:px-6 py-3">
                                    <span className="sr-only">Link</span>
                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                fullReportList
                                .map((report, i) => {
                                    return (
                                    <ReportListRow
                                        key={i}
                                        reportTitle={report.reportTitle}
                                        reportID={report.reportID}
                                        status={report.status}
                                    />
                                );
                            })
                         }

                        </tbody>
                    </table>
                </div>
            </div>
        </Route>

        <Route path={`${path}/:reportID`}>
          <ReportDetail 
            fullReportList={fullReportList}
            onStarChange={onStarChange}
            updateReportViewTimestamp={updateReportViewTimestamp}
          />
        </Route>
        </>

  );
}

ReportListRow.propTypes = {
    reportTitle: PropTypes.string.isRequired,
    reportID: PropTypes.string.isRequired,
    status: PropTypes.string,
  };
  
  function ReportListRow({ 
    reportTitle, 
    reportID,
    status, 
  }) {
    const { url } = useRouteMatch();
  
    return (
      <tr>
        <td className="px-3 lg:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate">
          <NavLink
            className="hover:underline"
            exact
            to={`/reports/${reportID}`}
          >
            {reportTitle}
          </NavLink>
        </td>
        <td className="hidden sm:table-cell tpx-3 md:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span 
            className={status === "Live" ? 
              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800":
              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
            }
          >
            {status}
          </span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <NavLink
            exact
            to={`/reports/${reportID}`}
            className="group fuel-text-teal hover:text-gray-900"
          >
            View
            <span className="ml-1 hidden sm:inline-block opacity-0 group-hover:opacity-100">
              →
            </span>
          </NavLink>
        </td>
      </tr>
    );
  }
  